import {
  format,
  isBefore,
  isAfter,
  differenceInDays,
  startOfDay,
} from "date-fns";
import DateDefaults from "@/entities/date-defaults.js";

export default {
  dateMinValue: Object.freeze(new Date("1970-01-01")),
  dateMaxValue: Object.freeze(new Date("9999-12-31")),

  formatDateToTypeISO_8601(date) {
    return format(date, DateDefaults.formats.ISO_8601);
  },
  formatDateToTypeLog(date) {
    return format(date, DateDefaults.formats.LOG);
  },
  formatDateToTypeClient(date) {
    return format(date, DateDefaults.formats.CLIENT);
  },
  formatDateToTypeOnlyDate(date) {
    return format(date, DateDefaults.formats.ONLY_DATE);
  },
  formatDateToTypeOnlyDay(date) {
    return format(date, DateDefaults.formats.ONLY_DAY);
  },
  formatDateToTypeOnlyDayAndMonth(date) {
    return format(date, DateDefaults.formats.ONLY_DAY_AND_MONTH);
  },
  formatDateToTypeOnlyMonthAndYear(date) {
    return format(date, DateDefaults.formats.ONLY_MONTH_AND_YEAR);
  },
  formatDateToTypeTime(date) {
    return format(date, DateDefaults.formats.ONLY_HOUR);
  },
  formatDateToTypeTimeNoSeconds(date) {
    return format(date, DateDefaults.formats.ONLY_HOUR_AND_MINUTE);
  },
  formatDateToNumberMonthYear(date) {
    return format(date, DateDefaults.formats.NUMBER_MONTH_YEAR);
  },

  formatDateToNameAndNumberDay(date, shortName) {
    return `${date.toLocaleDateString("it-IT", {
      day: "2-digit",
      weekday: shortName ? "short" : "long",
    })}`;
  },
  formatDateToNumberAndNameDay(date, shortName) {
    return `${date.toLocaleDateString("it-IT", {
      day: "2-digit",
    })} ${date.toLocaleDateString("it-IT", {
      weekday: shortName ? "short" : "long",
    })}
    `;
  },
  formatDateToNameAndNumberDayAndMonth(date, shortName) {
    return date.toLocaleDateString("it-IT", {
      weekday: shortName ? "short" : "long",
      day: "2-digit",
      month: "long",
    });
  },
  formatDateToNumberAndMonthAndNameDay(date, shortName) {
    return `${date.toLocaleDateString("it-IT", {
      day: "2-digit",
      month: "2-digit",
    })} ${date.toLocaleDateString("it-IT", {
      weekday: shortName ? "short" : "long",
    })}`;
  },

  isDateBetweenValidRange(date) {
    return date
      ? !isBefore(date, this.dateMinValue) && !isAfter(date, this.dateMaxValue)
      : false;
  },
  getDateMinTypeClient() {
    return this.formatDateToTypeClient(this.dateMinValue);
  },
  getDateMinTypeOnlyDate() {
    return this.formatDateToTypeOnlyDate(this.dateMinValue);
  },
  getDateMaxTypeClient() {
    return this.formatDateToTypeClient(this.dateMaxValue);
  },
  getDateMaxTypeOnlyDate() {
    return this.formatDateToTypeOnlyDate(this.dateMaxValue);
  },

  diffInDaysFromNow(date) {
    /*
      rif: https://date-fns.org/v2.29.3/docs/differenceInDays

      dateLeft	Date | Number	 the later date
      dateRight	Date | Number	 the earlier date

      Se non è scaduto, la giornata di oggi è prima della scadenza
    */
    return differenceInDays(startOfDay(Date.now()), date);
  },
};
