export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      utente: "/adminApi/utente/",
      utenteUser: "/userApi/utente/",
    },

    forzaLogoff(utente) {
      return this.call({
        method: this._method.PATCH,
        url: `${this.uris.utente}${utente.id}/forceLogoff`,
        needsAuth: true,
      });
    },

    inviaMailAttivazione(utente) {
      return this.call({
        method: this._method.PATCH,
        url: `${this.uris.utente}${utente.id}/attiva`,
        needsAuth: true,
      });
    },

    fetchUtenti() {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.utenteUser}`,
        needsAuth: true,
      });
    },

    addUtente(utente) {
      return this.call({
        method: this._method.POST,
        url: `${this.uris.utente}`,
        needsAuth: true,
        data: utente,
      });
    },

    editUtente(utente) {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.utente}`,
        needsAuth: true,
        data: utente,
      });
    },

    deleteUtente(idUtente) {
      return this.call({
        method: this._method.DELETE,
        url: `${this.uris.utente}${idUtente}`,
        needsAuth: true,
      });
    },
  };
}
