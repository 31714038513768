export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      documentiPeriodo: "/userApi/documento/",
    },

    fetchDocuments(periodo, idContratto) {
      return this.call({
        needsAuth: true,
        method: this._method.GET,
        url: `${this.uris.documentiPeriodo}${periodo}/${idContratto}`,
      });
    },

    fetchDocument(periodo, idContratto, nomeFile) {
      return this.call({
        needsAuth: true,
        method: this._method.GET,
        responseType: "blob",
        url: `${this.uris.documentiPeriodo}${periodo}/${idContratto}/${nomeFile}`,
      });
    },

    addDocument(idContratto, periodo, form) {
      return this.call({
        needsAuth: true,
        method: this._method.POST,
        url: `${this.uris.documentiPeriodo}${periodo}/${idContratto}/`,
        data: form,
      });
    },

    deleteDocument(idContratto, periodo, name) {
      return this.call({
        needsAuth: true,
        method: this._method.DELETE,
        url: `${this.uris.documentiPeriodo}${periodo}/${idContratto}/${name}`,
      });
    },
  };
}
