import {
  helperLocalStorage,
  helperSessionStorage,
} from "@/entities/storage-helper.js";
import { _copyObject } from "@/entities/funzioni-comuni";

export default {
  copyObject: _copyObject,
  helperLocalStorage,
  helperSessionStorage,
};

