export default Object.freeze({
  ERR_BAD_OPTION_VALUE: "Errore: valore opzione non ammesso",
  ERR_BAD_OPTION: "Errore: opzione non valida",
  ECONNABORTED:
    "Non è stato possibile contattare il server. Controllare la connessione.",
  ETIMEDOUT: "Errore: il server non ha risposto in tempo alla chiamata.",
  ERR_NETWORK: "Errore di rete. Controllare la connessione e riprovare.",
  ERR_FR_TOO_MANY_REDIRECTS: "Errore server.",
  ERR_DEPRECATED: "Errore: DEPRECATED",
  ERR_BAD_RESPONSE: "Errore: BAD_RESPONSE",
  ERR_BAD_REQUEST: "Errore: BAD_REQUEST",
  ERR_CANCELED: "Errore: connessione interrotta",
  ERR_NOT_SUPPORT: "Errore generico",
  ERR_INVALID_URL: "Errore nella chiamata al server",
});
