import { AlertType } from "@/entities/alert.js";
import DateDefaults from "@/entities/date-defaults.js";
import DateUtils from "@/entities/date-utils.js";
import { Attachment } from "@/entities/attachment";
import { DestinatarioRichiesta } from "@/entities/stato-richiesta.js";
import _ from "lodash";

const state = {
  richiestaSelezionata: null,
  richiesteInCorso: [],
  richiesteCompletate: [],
  filtroDataRichiesteCompletate: {
    da: DateUtils.formatDateToTypeClient(DateDefaults.UN_MESE_FA),
    a: DateUtils.formatDateToTypeClient(DateDefaults.OGGI),
  },
};

const getters = {
  richiestaSelezionata: (state) => state.richiestaSelezionata,
  richiesteCompletate: (state, _1, _2, rootGetters) => {
    return state.richiesteCompletate?.map((richiesta) => ({
      ...richiesta,
      descrizioneTipoRichiesta: rootGetters.tipoRichiestaFilteredByTipo(
        richiesta.codiceTipoRichiesta
      ).descrizione,
      destinatarioDecodificato: _.startCase(
        _.toLower(
          DestinatarioRichiesta.decodificaDestinatarioRichiesta(
            richiesta.destinatario
          )
        )
      ),
    }));
  },
  richiesteInCorso: (state, _1, _2, rootGetters) => {
    return state.richiesteInCorso?.map((richiesta) => ({
      ...richiesta,
      descrizioneTipoRichiesta: rootGetters.tipoRichiestaFilteredByTipo(
        richiesta.codiceTipoRichiesta
      ).descrizione,
      destinatarioDecodificato: _.startCase(
        _.toLower(
          DestinatarioRichiesta.decodificaDestinatarioRichiesta(
            richiesta.destinatario
          )
        )
      ),
    }));
  },
  filtroDataRichiesteCompletate: (state) => state.filtroDataRichiesteCompletate,
};

const actions = {
  setRichiestaSelezionata({ commit }, richiestaSelezionata) {
    commit("setRichiestaSelezionata", richiestaSelezionata);
  },
  setFiltroDataRichiesteCompletate({ commit }, filtro) {
    commit("setFiltroDataRichiesteCompletate", filtro);
  },

  async addRichiesta({ dispatch, commit }, richiesta) {
    try {
      const axiosResponse = await this._vm.$api.richieste.addRichiesta(
        richiesta
      );
      if (isStatusSuccess(axiosResponse.status)) {
        //va fatto perché mi serve settare la richiesta selezionata prima di fare il pagamento
        await commit("setRichiestaSelezionata", axiosResponse.data);
        await dispatch("fetchRichiesteInCorso");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async fetchRichiesteInCorso({ commit, dispatch }) {
    try {
      const axiosResponse = await this._vm.$api.richieste.fetchRichieste(false);
      if (isStatusSuccess(axiosResponse.status)) {
        const richieste = ordinaRichieste(axiosResponse.data);
        const richiesteConMessaggi = richieste.map((r) => {
          return {
            ...r,
            messaggi: [],
          };
        });
        commit("setRichiesteInCorso", richiesteConMessaggi);
      } else {
        commit("setRichiesteInCorso", []);
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async fetchRichiesteCompletate({ getters, commit, dispatch }) {
    try {
      const axiosResponse = await this._vm.$api.richieste.fetchRichieste(
        true,
        getters.filtroDataRichiesteCompletate.da,
        getters.filtroDataRichiesteCompletate.a
      );
      if (isStatusSuccess(axiosResponse.status)) {
        const richieste = ordinaRichieste(axiosResponse.data);
        const richiesteConMessaggi = richieste.map((r) => {
          return {
            ...r,
            messaggi: [],
          };
        });
        commit("setRichiesteCompletate", richiesteConMessaggi);
      } else {
        commit("setRichiesteCompletate", []);
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async addMessaggio({ dispatch, commit, getters }, { messaggio, attachment }) {
    try {
      const form = new FormData();
      form.append("messaggio", messaggio);
      if (attachment != null) {
        form.append("file", attachment.file);
      }
      const axiosResponse = await this._vm.$api.richieste.addMessaggio(
        getters.richiestaSelezionata.id,
        form
      );
      if (isStatusSuccess(axiosResponse.status)) {
        let messaggio = axiosResponse.data;
        messaggio.allegato = attachment;
        getters.richiestaSelezionata.messaggi.push(messaggio);

        await commit(
          "setMessaggiRichiestaSelezionata",
          getters.richiestaSelezionata.messaggi
        );
        createAndSetSuccessAlert(
          dispatch,
          true,
          "Messaggio inviato con successo"
        );
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async fetchMessaggi({ getters, commit, dispatch }) {
    try {
      const axiosResponse = await this._vm.$api.richieste.fetchMessaggi(
        getters.richiestaSelezionata.id
      );
      if (isStatusSuccess(axiosResponse.status)) {
        let messaggi = axiosResponse.data;
        messaggi = messaggi.map(function (ms) {
          return {
            ...ms,
            allegato:
              ms.nomeFile == null
                ? null
                : Attachment.buildAttachmentByName(ms.nomeFile),
          };
        });
        commit("setMessaggiRichiestaSelezionata", messaggi);
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },
  async visualizzaAllegato({ getters, dispatch }, messaggio) {
    try {
      const axiosResponse = await this._vm.$api.richieste.fetchAllegato(
        getters.richiestaSelezionata.id,
        messaggio.id
      );
      if (isStatusSuccess(axiosResponse.status)) {
        return await URL.createObjectURL(axiosResponse.data);
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async apriRichiesta({ dispatch }, idRichiesta) {
    try {
      const axiosResponse = await this._vm.$api.richieste.apriRichiesta(
        idRichiesta
      );
      if (isStatusSuccess(axiosResponse.status)) {
        createAndSetSuccessAlert(
          dispatch,
          true,
          "Richiesta riaperta con successo"
        );
        await dispatch("fetchRichiesteInCorso");
        await dispatch("fetchRichiesteCompletate");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async chiudiRichiesta({ dispatch }, idRichiesta) {
    try {
      const axiosResponse = await this._vm.$api.richieste.chiudiRichiesta(
        idRichiesta
      );

      if (isStatusSuccess(axiosResponse.status)) {
        createAndSetSuccessAlert(
          dispatch,
          true,
          "Richiesta chiusa con successo"
        );
        await dispatch("fetchRichiesteInCorso");
        await dispatch("fetchRichiesteCompletate");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },
};

function createAndSetErrorAlert(dispatch, msg, duration) {
  const alert = {
    show: true,
    type: AlertType.ERROR,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}

function createAndSetSuccessAlert(dispatch, show, msg) {
  const alert = {
    show,
    type: AlertType.SUCCESS,
    msg,
    duration: 3000,
  };
  setAlert(dispatch, alert);
}

function isStatusSuccess(statusCode) {
  return statusCode >= 200 && statusCode <= 299;
}

function setAlert(dispatch, alert) {
  dispatch("setAlert", alert, { root: true });
}

function ordinaRichieste(richieste) {
  return richieste.sort(function (a, b) {
    return new Date(b.created) - new Date(a.created);
  });
}

function ordinaMessaggi(messaggi) {
  return messaggi.sort(function (a, b) {
    return new Date(b.created) - new Date(a.created);
  });
}

const mutations = {
  setRichiesteCompletate(state, richiesteCompletate) {
    state.richiesteCompletate = richiesteCompletate;
  },

  setRichiesteInCorso(state, richiesteInCorso) {
    state.richiesteInCorso = richiesteInCorso;
  },

  setRichiestaSelezionata(state, richiestaSelezionata) {
    state.richiestaSelezionata = richiestaSelezionata;
  },

  setMessaggiRichiestaSelezionata(state, messaggi) {
    state.richiestaSelezionata.messaggi = ordinaMessaggi(messaggi);
  },

  setFiltroDataRichiesteCompletate(state, filtro) {
    state.filtroDataRichiesteCompletate = filtro;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

