import _ from "lodash";
export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      login: "/authentication/login",
      reset: "/authentication/resetRequest",
      registrazionePassword: "/authentication/changePassword",
    },
    /////////////////////////
    login(credenziali) {
      return this.call({
        method: this._method.POST,
        url: this.uris.login,
        data: credenziali,
      });
    },
    listaContratti() {
      return this.call({
        method: this._method.GET,
        url: this.uris.login,
      });
    },
    selezionaContratto(idContratto) {
      if (_.isNil(idContratto)) {
        throw "parametro idContratto mancante";
      }
      return this.call({
        method: this._method.PATCH,
        url: `${this.uris.login}/${idContratto}`,
      });
    },
    logout() {
      return this.call({
        method: this._method.DELETE,
        url: this.uris.login,
      });
    },

    reset(dati) {
      return this.call({
        method: this._method.PUT,
        params: {
          "g-recaptcha-response": dati.resetToken,
        },
        url: this.uris.reset,
        data: dati,
      });
    },
    settaPasswordUtente(utente, recaptchaToken) {
      return this.call({
        method: this._method.PUT,
        params: {
          "g-recaptcha-response": recaptchaToken,
        },
        url: `${this.uris.registrazionePassword}`,
        data: utente,
        needsAuth: false,
      });
    },
  };
}

