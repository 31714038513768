export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      contratto: "/adminApi/contratto/",
      variazioniContratto: "/userApi/dettaglioContratto/",
    },

    addContratto(idUtente, contratto) {
      return this.call({
        method: this._method.POST,
        url: `${this.uris.contratto}${idUtente}`,
        needsAuth: true,
        data: contratto,
      });
    },

    editContratto(contratto) {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.contratto}`,
        needsAuth: true,
        data: contratto,
      });
    },

    deleteContratto(idContratto) {
      return this.call({
        method: this._method.DELETE,
        url: `${this.uris.contratto}${idContratto}`,
        needsAuth: true,
      });
    },

    fetchVariazioniContratto(idContratto) {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.variazioniContratto}${idContratto}`,
        needsAuth: true,
      });
    },

    addVariazioneContratto(idContratto, variazione) {
      return this.call({
        method: this._method.POST,
        url: `${this.uris.variazioniContratto}${idContratto}`,
        needsAuth: true,
        data: variazione,
      });
    },

    editVariazioneContratto(idContratto, variazione) {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.variazioniContratto}${idContratto}`,
        needsAuth: true,
        data: variazione,
      });
    },

    deleteVariazioneContratto(idContratto, idVariazione) {
      return this.call({
        method: this._method.DELETE,
        url: `${this.uris.variazioniContratto}${idContratto}/${idVariazione}`,
        needsAuth: true,
      });
    },
  };
}
