export const day = {
  name: "Giorno",
  value: "day",
};
export const fourDays = {
  name: "4 giorni",
  value: "4day",
};
export const week = {
  name: "7 giorni",
  value: "week",
};
export const month = {
  name: "Mese",
  value: "month",
};

export const periods = [day, fourDays, week, month];

export const defaultTypeView = month;

export function getObjectFromValue(typeView) {
  return periods.find(({ value }) => value === typeView);
}

export function getPeriodsForRouter() {
  let routes = periods.map(({ value }) => value);
  routes.push(undefined); // se non viene passato alcun parametro
  return routes;
}

