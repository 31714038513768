const StatoAbilitazione = Object.freeze({
  PENDENTE: "DA CONVALIDARE",
  SCADUTA: "SCADUTA",
  IN_SCADENZA: "IN SCADENZA",
  VALIDA: "VALIDA",
  TERMINATA: "TERMINATA",

  getColor(stato) {
    switch (stato) {
      case this.PENDENTE:
        return "#0e52ac";
      case this.VALIDA:
        return "success";
      case this.IN_SCADENZA:
        return "warning";
      case this.SCADUTA:
        return "error";
      case this.TERMINATA:
        return "blue-grey";
    }
  },
});

export default StatoAbilitazione;
