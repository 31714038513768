const state = {
  isShow: false,
  isShowDettagli: false,
  isShowAttestati: false,
  isShowStatoSoggetti: false,
};

const getters = {
  getIsShow: (state) => state.isShow,
  getIsShowRowDettagli: (state) => state.isShowDettagli,
  getIsShowAttestati: (state) => state.isShowAttestati,
  getIsShowStatoSoggetti: (state) => state.isShowStatoSoggetti,
};

const actions = {
  async mostraRiga({ commit }, value) {
    commit("setIsShow", value);
  },
  async mostraDettagliRiga({ commit }, value) {
    commit("setIsShowRowDettagli", value);
  },
  async mostraStatoSoggetti({ commit }, value) {
    commit("setIsShowStatoSoggetti", value);
  },
  async mostraAttestati({ commit }, value) {
    commit("setIsShowAttestati", value);
  },
};

const mutations = {
  setIsShow(state, value) {
    state.isShow = value;
  },
  setIsShowRowDettagli(state, value) {
    state.isShowDettagli = value;
  },
  setIsShowAttestati(state, value) {
    state.isShowAttestati = value;
  },
  setIsShowStatoSoggetti(state, value) {
    state.isShowStatoSoggetti = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
