/* eslint-disable */
// https://stackoverflow.com/a/72856617/3739756
import axios from "axios";

export async function checkVersion(currentVersion) {
  const res = await axios.get(`/lastbuild.txt?${Date.now()}`);
  const serverVersion = await res.data;

  return serverVersion !== currentVersion;
}

