export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      signature: "/userApi/timbratura/",
    },

    fetchSignatures(idContratto, from, to) {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.signature}${idContratto}?from=${from}&to=${to}`,
        needsAuth: true,
      });
    },

    addSignature(idContratto, signature, forceSignature) {
      const urlParameter = forceSignature ? "?forza=true" : "";

      return this.call({
        method: this._method.POST,
        url: `${this.uris.signature}${idContratto}${urlParameter}`,
        needsAuth: true,
        data: signature,
      });
    },

    editSignature(idContratto, signature) {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.signature}${idContratto}`,
        needsAuth: true,
        data: signature,
      });
    },

    acceptSignaturesMonth(idContratto, dateFrom, dateTo) {
      return this.call({
        method: this._method.PATCH,
        url: `${this.uris.signature}${idContratto}?from=${dateFrom}&to=${dateTo}`,
        needsAuth: true,
      });
    },

    deleteSignature(idContratto, idSignature) {
      return this.call({
        method: this._method.DELETE,
        url: `${this.uris.signature}${idContratto}/${idSignature}`,
        needsAuth: true,
      });
    },
  };
}
