export const AuthLevelEnum = Object.freeze({
  USER: "USER",
  COMPANY_ADMIN: "COMPANY_ADMIN",
  TAX_ADVISOR: "TAX_ADVISOR",
  SYSTEM_ADMIN: "SYSTEM_ADMIN",
});

export default class AuthLevel {
  constructor(authLevel) {
    this.authLevel = authLevel;
  }

  getDescrizioneAuthLevel() {
    switch (this.authLevel) {
      case AuthLevelEnum.USER:
        return "Utente Normale";
      case AuthLevelEnum.COMPANY_ADMIN:
        return "Gestore Azienda";
      case AuthLevelEnum.TAX_ADVISOR:
        return "Consulente";
      case AuthLevelEnum.SYSTEM_ADMIN:
        return "Amministratore di Sistema";
    }
    throw new Error("Livello di autenticazione non gestito");
  }

  isUser() {
    return this.authLevel === AuthLevelEnum.USER;
  }
  isCompanyAdmin() {
    return this.authLevel === AuthLevelEnum.COMPANY_ADMIN;
  }
  isTaxAdvisor() {
    return this.authLevel === AuthLevelEnum.TAX_ADVISOR;
  }
  isSystemAdmin() {
    return this.authLevel === AuthLevelEnum.SYSTEM_ADMIN;
  }

  isAtLeastCompanyAdmin() {
    return this.isCompanyAdmin() || this.isTaxAdvisor() || this.isSystemAdmin();
  }
  isAtLeastTaxAdvisor() {
    return this.isTaxAdvisor() || this.isSystemAdmin();
  }
}

