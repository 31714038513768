const REGEXES = Object.freeze({
  PRIMO_CARATTERE: /^./,
  MAIUSCOLE: /([A-Z])/g,
  NO_ALPHANUM: /^[^A-Za-z0-9]+$/,
  NO_ALPHA: /[^A-Za-z]/g,
  ALPHANUM: /[A-Za-z0-9]/g,
  PUNTO_E_ALTRO: /\..*$/,
  NUMERO_FORMATO_IT: /^(?!0|00)[0-9]*(,\d{3})*(\.[0-9]{0,2})?$/,
  NUMERO_FORMATO_US: /^(?!0|00)[0-9]*(\.\d{3})*(,[0-9]{0,2})?$/,
  DECIMALI_A_ZERO: /\D00$/,
  BOLLO: /bollo/,
  CASSA_PREVIDENZIALE: /^cassaPrevidenziale_/,
  RITENUTA: /^ritenuta_/,
  BOLLO_O_CASSA: /bollo|^cassaPrevidenziale_/,
  BOLLO_CASSA_RITENUTA: /bollo|^cassaPrevidenziale_|^ritenuta_/,
  ERR_INTERNO_FORM_UTENTE: /Forms\.check|Doc\.getUtenteDaSalvare/,
  ERR_INTERNO_LOGIN: /Doc\.getUserDefaults/,
  RIQUADRO_UTENTE: /.*riquadro-utente/,
  ICON_INFORMATION: /.*information/,
  NUM_O_SEP_DEC: /[\d,.]/,
  TOK_ERR: /Errore nella gestione del token/,
  TOK_EXP: /Sessione scaduta, effettuare nuovamente la richiesta/,
  TOK_INVALID: /Token non valido/,
});

export default REGEXES;
