const ICON_JPG = "mdi-file-jpg-box";
const ICON_PNG = "mdi-file-png-box";
const ICON_PDF = "mdi-file-pdf-box";
const ICON_FILE = "mdi-file";

export class Attachment {
  constructor(file, name, type) {
    (this.file = file
      ? new File([file], file.name, { type: file.type })
      : null),
      (this.name = name),
      (this.type = type),
      (this.url = file ? URL.createObjectURL(file) : null),
      (this.extension = extractExtension(this.name));
  }

  static buildAttachmentByName(name) {
    return new Attachment(null, name, null);
  }

  static buildAttachmentByFile(file) {
    return new Attachment(file, file.name, file.type);
  }

  getIconNameByExtension() {
    switch (this.extension.toLowerCase()) {
      case "jpg":
      case "jpeg":
        return ICON_JPG;
      case "png":
        return ICON_PNG;
      case "pdf":
        return ICON_PDF;
      default:
        return ICON_FILE;
    }
  }

  getIconNameByTypeFile() {
    switch (this.type) {
      case TypeFile.JPEG:
        return ICON_JPG;
      case TypeFile.PNG:
        return ICON_PNG;
      case TypeFile.PDF:
        return ICON_PDF;
      default:
        return ICON_FILE;
    }
  }
  download() {
    try {
      const link = document.createElement("a");
      link.download = this.name;
      link.href = this.url;
      link.click();
    } catch (error) {
      throw Error("Errore nel download del file: " + error);
    }
  }

  generateFormData(metadata) {
    try {
      const form = new FormData();
      form.append("file", this.file);

      if (metadata) {
        for (let m in metadata) {
          form.append(m, metadata[m]);
        }
      }
      return form;
    } catch (error) {
      throw new Error(
        "Errore nella conversione in FormData dell'allegato: " + error
      );
    }
  }
}

function extractExtension(name) {
  if (name) {
    const index = name.lastIndexOf(".");
    if (index >= 0) {
      return name.substring(index + 1);
    }
  }
  return "";
}

export const TypeFile = Object.freeze({
  PDF: "application/pdf",
  JPEG: "image/jpeg",
  PNG: "image/png",
  ICO: "image/x-icon",
  AllImages: "image/*",
  // aggiungere quelli che interessano
});

export function typeFileToString(type) {
  switch (type) {
    case TypeFile.PDF:
      return "PDF";
    case TypeFile.JPEG:
      return "JPEG";
    case TypeFile.PNG:
      return "PNG";
    case TypeFile.ICO:
      return "ICO";
    case TypeFile.AllImages:
      return "tutti i tipi di immagine";
  }
  return "tipo non riconosciuto";
}
