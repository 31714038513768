const StatoElaborazione = Object.freeze({
  IDLE: "IDLE",
  ANTEPRIMA_PRESENZE: "ANTEPRIMA_PRESENZE",
  CONFERMA_DATI: "CONFERMA_DATI",
  ELABORAZIONE_LIGHT_BP: "ELABORAZIONE_LIGHT_BP",
  CREA_PREVIEW_BP: "CREA_PREVIEW_BP",
  VERIFICA_PREVIEW_BP: "VERIFICA_PREVIEW_BP",
  CREA_DEFINITIVA_BP: "CREA_DEFINITIVA_BP",

  decodificaStatoElaborazione(stato) {
    switch (stato) {
      case StatoElaborazione.IDLE:
        return "Inizio";
      case StatoElaborazione.ANTEPRIMA_PRESENZE:
        return "Anteprima presenze";
      case StatoElaborazione.CONFERMA_DATI:
        return "Conferma dati";
      case StatoElaborazione.ELABORAZIONE_LIGHT_BP:
        return "Elaborazione Busta Paga Manuale";
      case StatoElaborazione.CREA_PREVIEW_BP:
        return "Crea Anteprima Busta Paga";
      case StatoElaborazione.VERIFICA_PREVIEW_BP:
        return "Verifica Anteprima Busta Paga";
      case StatoElaborazione.CREA_DEFINITIVA_BP:
        return "Crea Definitiva Busta Paga";
      default:
        return stato;
    }
  },
});

export default StatoElaborazione;
