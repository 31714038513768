/* eslint-disable */

import { getDaysInMonth, format } from "date-fns";
import DateDefaults from "@/entities/date-defaults";
import { AlertType } from "@/entities/alert.js";
import { _copyObject } from "@/entities/funzioni-comuni";
import StatoElaborazione from "@/entities/stato-elaborazione.js";
import { idContrattoGenerico } from "@/entities/contractsList.js";
import store from "@/store";

const CONTRACT_TUTTI = {
  id: idContrattoGenerico,
  nameToShow: "TUTTI",
  nomeSoggetto: "TUTTI",
};

const state = {
  giorniMese: {},
  presenzeDipendenti: [],
  filtro: {
    anno: new Date().getFullYear(),
    mese: new Date().getMonth(),
  },
  waiting: false,
  idSoggettoModifica: null,
  isDialogVisible: false,
  dettaglioGiornoAttivo: null,
  isDialogDetailsSignatureVisible: false,
  contractSelectedSubject: null,
  contrattiVisualizzati: [],
  dialogFeriePermessiVisible: false,
  presenzeSoggettoSelected: null,
};

const getters = {
  waiting: (state) => state.waiting,
  tipiEvento: (_, _1, _2, rootGetters) =>
    Object.values(rootGetters.tipi.evento).filter(function (tipo) {
      return ![
        "FE",
        "ROL",
        "FNG",
        "ASSENTE",
        "FUORISEDE",
        "PRESENTE",
        "RC",
        "EVE",
      ].includes(tipo.codice);
    }),

  idSoggettoModifica: (state) => state.idSoggettoModifica,
  presenzeDipendenti: (state) => {
    return state.presenzeDipendenti.sort((a, b) => {
      if (a.contratto.nomeSoggetto < b.contratto.nomeSoggetto) {
        return -1;
      }
      if (a.contratto.nomeSoggetto > b.contratto.nomeSoggetto) {
        return 1;
      }
      return 0;
    });
  },

  giorniMese: (state) => {
    const refDate = new Date(state.filtro.anno, state.filtro.mese);
    const dayNames = ["dom", "lun", "mar", "mer", "gio", "ven", "sab"];
    const daysInMonth = getDaysInMonth(refDate);

    let curDay = refDate.getDay();
    let giorniMese = [];

    for (let i = 0; i < daysInMonth; i++) {
      const pad = (n) => n.toString().padStart(2, "0");
      const pos = curDay++ % dayNames.length;
      const strData = `${state.filtro.anno}-${pad(state.filtro.mese + 1)}-${pad(
        i + 1
      )}`;
      giorniMese[strData] = dayNames[pos];
    }

    return giorniMese;
  },
  nomeGiorno: (state, getters) => (giorno) => getters.giorniMese[giorno],
  filtro: (state) => state.filtro,
  filtroAnno: (state) => state.filtro.anno,
  filtroMese: (state) => state.filtro.mese,
  periodoFiltro: (state) =>
    format(
      new Date(state.filtro.anno, state.filtro.mese),
      DateDefaults.formats.CLIENT
    ),
  isDialogVisible: (state) => state.isDialogVisible,
  isDialogDetailsSignatureVisible: ({ isDialogDetailsSignatureVisible }) =>
    isDialogDetailsSignatureVisible,
  contractSelectedSubject: ({ contractSelectedSubject }) =>
    contractSelectedSubject,
  dettaglioGiornoAttivo: (state) => state.dettaglioGiornoAttivo,
  contrattiVisualizzati: (state) => state.contrattiVisualizzati,
  isDialogFeriePermessiVisible: (state) => state.dialogFeriePermessiVisible,
  presenzeSoggettoSelected: (state) => state.presenzeSoggettoSelected,
};

const mutations = {
  setWaiting(state, waiting) {
    state.waiting = waiting;
  },
  setIDSoggettoModifica(state, id) {
    state.idSoggettoModifica = id;
  },
  setPresenzeDipendenti(state, presenze) {
    state.presenzeDipendenti = presenze;
  },

  setGiorniMese(state, giorni) {
    state.giorniMese = giorni;
  },
  setFiltroAnno(state, anno) {
    state.filtro.anno = anno;
  },
  setFiltroMese(state, mese) {
    state.filtro.mese = mese;
  },
  showDialogEvent: (state, isDialogVisible) =>
    (state.isDialogVisible = isDialogVisible),
  setDialogDetailsSignatureVisible: (state, isVisible) =>
    (state.isDialogDetailsSignatureVisible = isVisible),
  setContractSelectedSubject: (state, contract) =>
    (state.contractSelectedSubject = contract),

  dettaglioGiornoAttivo: (state, dettaglio) =>
    (state.dettaglioGiornoAttivo = dettaglio),

  setContrattiVisualizzati: (state, contratti) =>
    (state.contrattiVisualizzati = contratti),

  setDialogFeriePermessiVisible: (state, isDialogVisible) =>
    (state.dialogFeriePermessiVisible = isDialogVisible),

  setPresenzeSoggettoSelected: (state, presenzeSoggettoSelected) =>
    (state.presenzeSoggettoSelected = presenzeSoggettoSelected),
};

const actions = {
  setWaiting({ commit }, waiting = true) {
    commit("setWaiting", waiting);
  },
  setGiorniMese({ commit }, giorni) {
    commit("setGiorniMese", giorni);
  },
  setFiltroAnno({ commit }, anno) {
    commit("setFiltroAnno", anno);
  },
  setFiltroMese({ commit }, mese) {
    commit("setFiltroMese", mese);
  },
  setIDSoggettoModifica({ commit }, id) {
    commit("setIDSoggettoModifica", id);
  },
  setShowDialogEvent({ commit }, isDialogVisible) {
    if (!isDialogVisible) {
      commit("dettaglioGiornoAttivo", null);
    }
    commit("showDialogEvent", isDialogVisible);
  },
  setDettaglioGiornoAttivo({ commit }, dettaglio) {
    commit("dettaglioGiornoAttivo", dettaglio);
  },
  setDialogDetailsSignatureVisible({ commit }, isVisible) {
    commit("setDialogDetailsSignatureVisible", isVisible);
  },
  setContractSelectedSubject({ commit }, contract) {
    commit("setContractSelectedSubject", contract);
  },

  setDialogFeriePermessiVisible({ commit }, isDialogVisible) {
    commit("setDialogFeriePermessiVisible", isDialogVisible);
  },

  setPresenzeSoggettoSelected({ commit }, presenzeSoggettoSelected) {
    commit("setPresenzeSoggettoSelected", presenzeSoggettoSelected);
  },

  modificaDatiPresenze({ dispatch, getters }, { id, giorno, ore }) {
    const presenze = getters.presenzeDipendenti;
    Object.keys(ore.altreOre).forEach((codice) => {
      if (ore.altreOre[codice] == 0) {
        delete ore.altreOre[codice];
      }
    });
    presenze[id].presenze[giorno] = ore;
    dispatch("setPresenzeDipendenti", presenze);
  },

  setPresenzeDipendenti({ commit }, presenze) {
    commit("setPresenzeDipendenti", presenze);
  },

  aggiornaPresenzeDipendente({ state }, presenzeDipdendente) {
    for (let i = 0; i < state.presenzeDipendenti.length; i++) {
      if (
        state.presenzeDipendenti[i].contratto.id ===
        presenzeDipdendente.contratto.id
      ) {
        state.presenzeDipendenti[i] = _copyObject(presenzeDipdendente);
      }
    }
  },

  async setContrattiVisualizzati({ rootGetters, getters, commit }) {
    let contratti = [
      ...rootGetters.contrattiConTimbratureByMonthAndYearSelected,
    ];

    //Solo l'admin potrà vedere le presenze di tutti
    if (rootGetters.isAtLeastCompanyAdmin) {
      await store.dispatch("elaborazioneBase/fetchStatoElaborazioneAzienda");
      //1. il periodo selezionato è uguale al periodo corrente e lo stato non è IDLE
      if (getters.periodoFiltro === rootGetters.azienda.periodoCorrente) {
        if (
          rootGetters.azienda.statoElaborazione !== StatoElaborazione.IDLE &&
          !rootGetters.azienda.busyElaborazione
        ) {
          contratti.unshift(CONTRACT_TUTTI);
        }
      } else if (getters.periodoFiltro < rootGetters.azienda.periodoCorrente) {
        contratti.unshift(CONTRACT_TUTTI); //2. il periodo selezionato è minore del periodo corrente
      }
    }
    commit("setContrattiVisualizzati", contratti);
  },

  async fetchAllPresenzeMese({ getters, commit, dispatch }) {
    dispatch("setWaiting");
    try {
      let response = await this._vm.$api.presenze.fetchAllPresenzeMese(
        getters.periodoFiltro
      );

      if (isStatusSuccess(response.status)) {
        dispatch("setPresenzeDipendenti", response.data);
      } else {
        createAndSetErrorAlert(dispatch, response.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error.message);
    }
    dispatch("setWaiting", false);
  },

  async fetchPresenzeMeseContratto(
    { getters, commit, dispatch },
    { idContratto, isModifica }
  ) {
    dispatch("setWaiting");
    try {
      let response = await this._vm.$api.presenze.fetchPresenzeMeseContratto(
        getters.periodoFiltro,
        idContratto
      );
      if (isStatusSuccess(response.status)) {
        const presenze = response.data[0];
        if (isModifica) {
          dispatch("aggiornaPresenzeDipendente", presenze);
        } else {
          dispatch("setPresenzeDipendenti", response.data);
        }
      } else {
        createAndSetErrorAlert(dispatch, response.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error.message);
    }
    dispatch("setWaiting", false);
  },

  async editPresenzeMeseContratto(
    { getters, dispatch },
    { presenze, idContratto }
  ) {
    dispatch("setWaiting");
    try {
      const response = await this._vm.$api.presenze.editPresenzeMeseContratto(
        presenze,
        getters.periodoFiltro,
        idContratto
      );
      if (isStatusSuccess(response.status)) {
        dispatch("aggiornaPresenzeDipendente", response.data);
        createAndSetSuccessAlert(
          dispatch,
          true,
          "Modifica presenze effettuata con successo"
        );
      } else {
        createAndSetErrorAlert(dispatch, response.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error.message);
    }
    dispatch("setWaiting", false);
  },

  async rigeneraPresenzeMeseContratto(
    { getters, commit, dispatch },
    idContratto
  ) {
    dispatch("setWaiting");
    try {
      const response =
        await this._vm.$api.presenze.rigeneraPresenzeMeseContratto(
          getters.periodoFiltro,
          idContratto
        );
      if (isStatusSuccess(response.status)) {
        createAndSetSuccessAlert(
          dispatch,
          true,
          "Rigenerazione presenze effettuata con successo"
        );
        dispatch("aggiornaPresenzeDipendente", response.data);
      } else {
        createAndSetErrorAlert(dispatch, response.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error.message);
    }

    dispatch("setWaiting", false);
  },

  async editFerieSoggettoByPeriodo({ getters, commit, dispatch }, rateiFerie) {
    try {
      const response = await this._vm.$api.presenze.editRateiFerie(
        rateiFerie,
        getters.periodoFiltro,
        getters.presenzeSoggettoSelected.contratto.id
      );
      if (isStatusSuccess(response.status)) {
        dispatch("aggiornaPresenzeDipendente", response.data);
      } else {
        createAndSetErrorAlert(dispatch, response.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error.message);
    }
  },

  async editPermessiSoggettoByPeriodo(
    { getters, commit, dispatch },
    rateiPermessi
  ) {
    try {
      const response = await this._vm.$api.presenze.editRateiPermessi(
        rateiPermessi,
        getters.periodoFiltro,
        getters.presenzeSoggettoSelected.contratto.id
      );
      if (isStatusSuccess(response.status)) {
        dispatch("aggiornaPresenzeDipendente", response.data);
      } else {
        createAndSetErrorAlert(dispatch, response.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error.message);
    }
  },
};

function isStatusSuccess(statusCode) {
  return statusCode >= 200 && statusCode <= 299;
}

function createAndSetErrorAlert(dispatch, msg, duration) {
  const alert = {
    show: true,
    type: AlertType.ERROR,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}

function createAndSetSuccessAlert(dispatch, show, msg, duration) {
  const alert = {
    show,
    type: AlertType.SUCCESS,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}

function setAlert(dispatch, alert) {
  dispatch("setAlert", alert, { root: true });
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
