import { AlertType } from "@/entities/alert.js";
import { format, parse, getMonth, subMonths } from "date-fns";
import DateDefaults from "@/entities/date-defaults";
import store from "@/store"; // Adjust the path based on your project structure
import { _copyObject } from "@/entities/funzioni-comuni";

const state = {
  isShowCalcolo: false,
  variazioniContratto: [],
  variazioneContratto: null,
  variazioneContrattoTemp: null,
  mostraTabellaVariazioni: true,
  rateoFerie: null,
  rateoPermessi: null,
  isRisultatoAssente: false,
  mesePrecedente: -1,
  showDialogElementoFisso: false,
  selectedElementoFisso: null,
  infoCreatedOrLastMod: null,
};

const getters = {
  isShowCalcolo: ({ isShowCalcolo }) => isShowCalcolo,
  variazioniContratto: ({ variazioniContratto }) => variazioniContratto,
  variazioneContratto: ({ variazioneContratto }) => variazioneContratto,
  variazioneContrattoTemp: ({ variazioneContrattoTemp }) =>
    variazioneContrattoTemp,
  rateoFerie: ({ rateoFerie }) => rateoFerie,
  rateoPermessi: ({ rateoPermessi }) => rateoPermessi,
  mostraTabellaVariazioni: ({ mostraTabellaVariazioni }) =>
    mostraTabellaVariazioni,
  isRisultatoAssente: ({ isRisultatoAssente }) => isRisultatoAssente,
  mesePrecedente: ({ mesePrecedente }) => mesePrecedente,
  showDialogElementoFisso: ({ showDialogElementoFisso }) =>
    showDialogElementoFisso,
  selectedElementoFisso: ({ selectedElementoFisso }) => selectedElementoFisso,
  infoCreatedOrLastMod: ({ infoCreatedOrLastMod }) => infoCreatedOrLastMod,
};

const actions = {
  mostraCalcolo({ commit }, value) {
    commit("setIsShowCalcolo", value);
  },
  svuotaValori({ commit }) {
    commit("setVariazioniContratto", []);
    commit("setRateoFerie", null);
    commit("setRateoPermessi", null);
  },

  setVariazioneContratto({ commit }, variazione) {
    commit("setVariazioneContratto", variazione);
  },

  setInfoCreatedOrLastMod({ commit }, infoCreatedOrLastMod) {
    commit("setInfoCreatedOrLastMod", infoCreatedOrLastMod);
  },
  setVariazioneContrattoTemp({ commit }, variazione) {
    commit("setVariazioneContrattoTemp", variazione);
  },
  setMostraTabellaVariazioni({ commit }, mostra) {
    commit("setMostraTabellaVariazioni", mostra);
  },

  deleteRecordArray({ commit }, idToDelete) {
    commit("deleteRecordArray", idToDelete);
  },

  setShowDialogElementoFisso({ commit }, show) {
    commit("setShowDialogElementoFisso", show);
  },

  setSelectedElementoFisso({ commit }, selectedElementoFisso) {
    commit("setSelectedElementoFisso", selectedElementoFisso);
  },

  addElementoFisso({ state, commit }, elementoFisso) {
    let righeFisse = _copyObject(state.variazioneContrattoTemp.righeFisse);
    righeFisse.push(elementoFisso);
    commit("setRigheFisse", righeFisse);
  },

  editElementoFisso({ state, commit }, selectedElementoFisso) {
    if (selectedElementoFisso) {
      let righeFisse = _copyObject(state.variazioneContrattoTemp.righeFisse);
      if (righeFisse?.length > 0) {
        righeFisse[selectedElementoFisso.index] =
          selectedElementoFisso.elementoFisso;
      }

      commit("setRigheFisse", righeFisse);
    }
  },

  deleteElementoFisso({ commit }, index) {
    let righeFisse = _copyObject(state.variazioneContrattoTemp.righeFisse);
    righeFisse.splice(index, 1);

    commit("setRigheFisse", righeFisse);
  },

  async fetchCalcoloRateiFuturiContratto(
    { commit, dispatch },
    { idContratto }
  ) {
    try {
      const azienda = store.getters.azienda;
      // Prendo il periodo corrente, lo converto in data, sottraggo un mese e riconverto in stringa
      const periodoPrecedente = format(
        subMonths(
          parse(
            azienda.periodoCorrente,
            DateDefaults.formats.CLIENT,
            new Date()
          ),
          1
        ),
        DateDefaults.formats.CLIENT
      );

      const mesePrecedente = getMonth(
        parse(periodoPrecedente, DateDefaults.formats.CLIENT, new Date())
      );

      commit("setMesePrecedente", mesePrecedente);
      //A gennaio non faccio la chiamata
      if (mesePrecedente !== 11) {
        let response = await this._vm.$api.presenze.fetchPresenzeMeseContratto(
          periodoPrecedente,
          idContratto
        );
        if (isStatusSuccess(response.status)) {
          // console.log("dati", response.data[0]);
          // se mancano i dati non mostro nulla
          if (response.data[0].missing) {
            commit("setIsRisultatoAssente", true);
          } else {
            commit("setIsRisultatoAssente", false);
            commit(
              "setRateoFerie",
              convertNumbers(response.data[0].rateoFerie)
            );
            commit(
              "setRateoPermessi",
              convertNumbers(response.data[0].rateoPermessi)
            );
            commit("setInfoCreatedOrLastMod", {
              created: response.data[0].created,
              lastMod: response.data[0].lastMod,
            });
          }
        } else {
          createAndSetErrorAlert(dispatch, response.response.data);
        }
      } else {
        commit("setIsRisultatoAssente", true);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error.message);
    }
  },

  async fetchVariazioniContratto({ commit, dispatch, rootGetters }) {
    try {
      const axiosResponse =
        await this._vm.$api.datiContratto.fetchVariazioniContratto(
          rootGetters.selectedContract.id
        );
      if (isStatusSuccess(axiosResponse.status)) {
        const dettagliConInfoAggiuntive = axiosResponse.data;
        commit(
          "setVariazioniContratto",
          aggiungiProprietaAVariazioniContratto(
            dettagliConInfoAggiuntive,
            rootGetters.selectedContract.iddettaglioCorrente
          )
        );
      } else {
        commit("setVariazioniContratto", []);
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      commit("setVariazioniContratto", []);
      createAndSetErrorAlert(dispatch, message);
    }
  },

  async addVariazioneContratto({ getters, dispatch, rootGetters }) {
    const functionHTTP = () =>
      this._vm.$api.datiContratto.addVariazioneContratto(
        rootGetters.selectedContract.id,
        getters.variazioneContratto
      );
    const addRecordToArray = async (nuovaVariazione) => {
      await getters.variazioniContratto.push(nuovaVariazione);
      aggiungiProprietaAVariazioniContratto(
        getters.variazioniContratto,
        rootGetters.selectedContract.iddettaglioCorrente
      );
    };

    await executeFunctionHTTPVariazioneContratto(
      dispatch,
      "Variazione aggiunta",
      functionHTTP,
      addRecordToArray
    );

    await store.dispatch("setDatiApplicazione");
  },

  async editVariazioneContratto({ getters, dispatch, rootGetters }) {
    const functionHTTP = () =>
      this._vm.$api.datiContratto.editVariazioneContratto(
        rootGetters.selectedContract.id,
        getters.variazioneContratto
      );
    const replaceRecordArray = (variazioneModificata) => {
      const index = findIndexByID(
        getters.variazioniContratto,
        getters.variazioneContratto.id
      );
      if (index > -1) {
        getters.variazioniContratto[index] = variazioneModificata;
        aggiungiProprietaAVariazioniContratto(
          getters.variazioniContratto,
          rootGetters.selectedContract.iddettaglioCorrente
        );
      } else {
        throw new Error("Dettaglio da modificare non trovato");
      }
    };

    await executeFunctionHTTPVariazioneContratto(
      dispatch,
      "Variazione modificata",
      functionHTTP,
      replaceRecordArray
    );

    // commit("mostraDaRicaricare", true);

    await store.dispatch("setDatiApplicazione");
  },

  async deleteVariazioneContratto(
    { getters, dispatch, rootGetters },
    idVariazione
  ) {
    const functionHTTP = () =>
      this._vm.$api.datiContratto.deleteVariazioneContratto(
        rootGetters.selectedContract.id,
        idVariazione
      );
    const deleteRecordArray = async () => {
      await dispatch("deleteRecordArray", idVariazione);
      aggiungiProprietaAVariazioniContratto(
        getters.variazioniContratto,
        rootGetters.selectedContract.iddettaglioCorrente
      );
    };

    await executeFunctionHTTPVariazioneContratto(
      dispatch,
      "Variazione eliminata",
      functionHTTP,
      deleteRecordArray
    );

    await store.dispatch("setDatiApplicazione");
  },
};

const mutations = {
  setMesePrecedente(state, value) {
    state.mesePrecedente = value;
  },
  setIsRisultatoAssente(state, value) {
    state.isRisultatoAssente = value;
  },
  setRateoFerie(state, value) {
    state.rateoFerie = value;
  },
  setRateoPermessi(state, value) {
    state.rateoPermessi = value;
  },
  setIsShowCalcolo(state, value) {
    state.isShowCalcolo = value;
  },
  setVariazioniContratto: (state, variazioniContratto) =>
    (state.variazioniContratto = variazioniContratto),
  setVariazioneContratto: (state, variazioneContratto) =>
    (state.variazioneContratto = variazioneContratto),
  setVariazioneContrattoTemp: (state, variazioneContratto) =>
    (state.variazioneContrattoTemp = variazioneContratto),
  setMostraTabellaVariazioni: (state, mostra) =>
    (state.mostraTabellaVariazioni = mostra),
  deleteRecordArray(state, idToDelete) {
    const index = state.variazioniContratto.findIndex(
      ({ id }) => id === idToDelete
    );
    if (index > -1) {
      state.variazioniContratto.splice(index, 1);
    }
  },
  setShowDialogElementoFisso(state, show) {
    state.showDialogElementoFisso = show;
  },
  setSelectedElementoFisso(state, selectedElementoFisso) {
    state.selectedElementoFisso = selectedElementoFisso;
  },

  setRigheFisse(state, righeFisse) {
    state.variazioneContrattoTemp.righeFisse = _copyObject(righeFisse) || [];
  },

  setInfoCreatedOrLastMod(state, infoCreatedOrLastMod) {
    state.infoCreatedOrLastMod = infoCreatedOrLastMod;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

// Funzione per convertire i numeri dentro l'oggetto in decimale con due cifre
function convertNumbers(obj) {
  for (const key in obj) {
    if (typeof obj[key] === "number") {
      obj[key] = parseFloat(obj[key].toFixed(2));
    }
  }
  return obj;
}

function findIndexByID(array, idToFind) {
  return array.findIndex(({ id }) => id === idToFind);
}

async function executeFunctionHTTPVariazioneContratto(
  dispatch,
  successMessage,
  callBackFunctionHTTP,
  callBackSuccessOperation
) {
  try {
    const axiosResponse = await callBackFunctionHTTP();
    if (isStatusSuccess(axiosResponse.status)) {
      await callBackSuccessOperation(axiosResponse.data);
      createAndSetSuccessAlert(dispatch, true, successMessage);
    } else {
      createAndSetErrorAlert(dispatch, axiosResponse.response.data);
    }
  } catch ({ message }) {
    createAndSetErrorAlert(dispatch, message);
  }
  return null;
}

function isStatusSuccess(statusCode) {
  return statusCode >= 200 && statusCode <= 299;
}

function createAndSetSuccessAlert(dispatch, show, msg, duration) {
  const alert = {
    show,
    type: AlertType.SUCCESS,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}
function createAndSetErrorAlert(dispatch, msg, duration) {
  const alert = {
    show: true,
    type: AlertType.ERROR,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}
function setAlert(dispatch, alert) {
  dispatch("setAlert", alert, { root: true });
}

function aggiungiProprietaAVariazioniContratto(
  variazioniContratto,
  idDettaglioContrattoAttivo
) {
  return variazioniContratto.map((v) =>
    aggiungiProprietaAVariazione(v, idDettaglioContrattoAttivo)
  );
}
function aggiungiProprietaAVariazione(variazione, idDettaglioContrattoAttivo) {
  return {
    ...variazione,
    percentualePT: variazione.percentualePT * 100,
    percentualeContributiINPS: variazione.percentualeContributiINPS * 100,
    attivo: idDettaglioContrattoAttivo === variazione.id || false,
  };
}
