// import { camelCase, startCase } from "lodash";

const stateEvents = Object.freeze({
  state: {
    approvato: {
      name: "APPROVATO",
      color: "success",
      opacity: 1.0,
    },
    pendente: {
      name: "PENDENTE",
      color: "warning",
      opacity: 0.3,
    },
    respinto: {
      name: "RESPINTO",
      color: "error",
      opacity: 0.5,
    },
  },

  toArray() {
    return [this.state.approvato, this.state.pendente, this.state.respinto];
  },

  defaultState(isAdmin) {
    return isAdmin ? this.state.approvato : this.state.pendente;
  },
  defaultStateCalendar(isAdmin, codiceTipoEvento) {
    return isAdmin || codiceTipoEvento === "EVE"
      ? this.state.approvato
      : this.state.pendente;
  },

  isStateApprovatoOrPendente(state) {
    return this.isStateApprovato(state) || this.isStatePendente(state);
  },
  isStateApprovato(state) {
    return state === this.state.approvato.name;
  },
  isStatePendente(state) {
    return state === this.state.pendente.name;
  },

  getStateByName(name) {
    // switch (startCase(camelCase(name))) {
    switch (name) {
      case this.state.approvato.name:
        return this.state.approvato;
      case this.state.pendente.name:
        return this.state.pendente;
      case this.state.respinto.name:
        return this.state.respinto;
    }
  },

  getOpacityColorByState(name) {
    return this.getStateByName(name)?.opacity;
  },
});

export default stateEvents;
