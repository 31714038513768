import Vue from "vue";
import Vuex from "vuex";
import store from "@/store";
import jwt_decode from "jwt-decode";

import {
  isAfter,
  isBefore,
  isEqual,
  isSameMonth,
  isWithinInterval,
  parse,
  endOfMonth,
  addMonths,
  subYears,
} from "date-fns";
// import LZString from "lz-string";
import _ from "lodash";
import { _l } from "@/entities/log";

import DateUtils from "@/entities/date-utils";
import REGEXES from "@/entities/regexes";
import router from "@/router";
// gestione errori
import { Alert, AlertType } from "@/entities/alert";
// auth
import authModule from "@/store/modules/auth";
import calendarEvent from "@/store/modules/calendar/event";
import signing from "@/store/modules/signing/index.js";
import datiContratto from "@/store/modules/datiContratto";
// rimborsi
import rimborsiBase from "@/store/modules/rimborsi/base";
import rimborsiEvent from "@/store/modules/rimborsi/event";
import rimborsiPeriodi from "@/store/modules/rimborsi/periodi";
// presenze
import presenze from "@/store/modules/presenze/presenze";
import DateDefaults from "@/entities/date-defaults.js";
// abilitazioni
import abilitazioniBase from "@/store/modules/abilitazioni/base";
import abilitazioniEvent from "@/store/modules/abilitazioni/event";
//richieste
import richieste from "@/store/modules/richieste/richieste";
//documenti
import documento from "@/store/modules/documento/documento";
//utenti
import utentiBase from "@/store/modules/utenti/base";
import utentiEvent from "@/store/modules/utenti/event";
//azienda
import aziendaBase from "@/store/modules/azienda/base";
//elaborazione
import elaborazioneBase from "@/store/modules/elaborazione/base";

// import tutils from "@/store/modules/tutils";
import LoginSteps from "@/entities/login-steps.js";
import AuthLevel from "@/entities/auth-level";

import TipoAzienda from "@/entities/tipo-azienda";
import { DestinatarioRichiesta } from "@/entities/stato-richiesta.js";

Vue.use(Vuex);

const TIPO_RAPPORTO_DIPENDENTE = "DIPENDENTE";

// Vuex plugin - al momento non usato
const storeHelpers = (store) => {
  store.setSelectedContract = function (appData) {
    const idContrattoAttivo = _l(
      "idContrattoAttivo",
      store.getters["auth/contrattoAttivo"]
    );
    let selectedContract = appData.contrattiGestibili.find(
      (c) => c.id === idContrattoAttivo
    );
    if (_.isNil(selectedContract)) {
      selectedContract = appData.contrattiGestibili[0];
    }
    store.commit("setSelectedContract", selectedContract);
  };
};

// const findIDContrattoAttivo = (appData) => {
//   if (appData.contratto == null) {
//     const authLevel = new AuthLevel(appData.utente.authLevel);
//     if (authLevel.isAtLeastTaxAdvisor()) {
//       if (appData.azienda) {
//         return appData.azienda.id;
//       }
//       throw new Error("Dati azienda assenti");
//     }
//     throw new Error("Non è stato trovato alcun contratto attivo");
//   }
//   return appData.contratto.id;
// };

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
//   reducer: (state) => ({
//     s:
//       _.isEmpty(state.auth.token) ||
//       _.isNil(state.auth.token) ||
//       !state.auth.loginStep === LoginSteps.CREDENZIALI
//         ? ""
//         : LZString.compressToUTF16(
//             tutils.build(state.auth.token, state.auth.loginStep)
//           ),
//     idca:
//       _.isNil(state.auth.token) ||
//       _.isEmpty(state.auth.token) ||
//       _.isNil(state.applicationData) ||
//       _.isEmpty(state.applicationData)
//         ? ""
//         : LZString.compressToUTF16(
//             JSON.stringify(findIDContrattoAttivo(state.applicationData))
//           ),
//   }),
//   filter: (mutation) => {
//     // console.log("type", mutation.type);
//     const ret = [
//       "auth/setLoginStep",
//       "auth/resetState",
//       "setDatiApplicazione",
//     ].includes(mutation.type);
//     // console.log(ret);
//     return ret;
//   },
// });

export default new Vuex.Store({
  state: {
    applicationData: {},
    activeSection: null,
    selectedContract: null,
    monthAndYearSelected: DateUtils.formatDateToNumberMonthYear(new Date()),
    axiosError: null,
    serverError: false,
    alert: null,
    updateAvailable: false,
    isReloadRequired: false,
    windowWidth: window.innerWidth,
    primoPeriodoValido: DateUtils.formatDateToNumberMonthYear(
      subYears(new Date(), 1)
    ),
    ultimoPeriodoValido: DateUtils.formatDateToNumberMonthYear(
      addMonths(new Date(), 1)
    ),
  },
  modules: {
    auth: authModule,
    calendarEvent,
    signing,
    rimborsiBase,
    rimborsiEvent,
    rimborsiPeriodi,
    presenze,
    abilitazioniBase,
    abilitazioniEvent,
    documento,
    datiContratto,
    richieste,
    utentiBase,
    utentiEvent,
    aziendaBase,
    elaborazioneBase,
  },
  mutations: {
    setDatiApplicazione: (state, dati) => (state.applicationData = dati),
    setAziendaDatiApplicazione: (state, azienda) =>
      (state.applicationData.azienda = azienda),
    setDatiAppFromStorage: (state, dati) => (state.applicationData = dati),
    setAxiosError: (state, errore) => {
      if (errore)
        state.axiosError = _l("store/index:106 setAxiosError", errore);
    },
    setServerError: (state, hasErrors) => (state.serverError = hasErrors),
    setAlert: (state, alert) => (state.alert = alert),
    setActiveSection: (state, activeSection) =>
      (state.activeSection = activeSection),
    setSelectedContract: (state, c) => (state.selectedContract = c),
    setMonthAndYearSelected: (state, monthAndYear) =>
      (state.monthAndYearSelected = monthAndYear),
    setUpdateAvailable: (state, isUpdateAvailable) =>
      (state.updateAvailable = isUpdateAvailable),
    setIsReloadRequired: (state, isReloadRequired) =>
      (state.isReloadRequired = isReloadRequired),
    setWindowWidth: (state, windowWidth) => (state.windowWidth = windowWidth),
    setAvvisoGenerale: (state, avvisoGenerale) =>
      (state.avvisoGenerale = avvisoGenerale),
  },
  actions: {
    async redirectCambiaContratto({ commit, dispatch, state }) {
      // console.log("state.auth.token", state.auth.token);
      // console.log("state.auth.loginStep", state.auth.loginStep);
      if (
        state.auth.token !== null &&
        state.auth.loginStep === LoginSteps.CREDENZIALI
      ) {
        /**
         * Questa parte ha il compito di assegnare un refresh
         * token qualora io abbia fatto un login in precedenza
         * e stia aprendo la pagina su una nuova scheda con "/login"
         * nell'URL. In questo caso nell'api-base risulta un needsAuth = false
         * Quindi non farebbe il refresh del token, prima di richiederlo però
         * controlla se effettivamente è scaduto.
         */
        let valid = true;
        let tokParts;
        // verifica token
        // se scaduto refresh
        try {
          tokParts = jwt_decode(store.getters["auth/token"]);
          // console.log("tokParts", tokParts);
        } catch (error) {
          // se token non valido tento il refresh
          valid = false;
        }
        if (!valid || isBefore(new Date(tokParts.exp * 1000), new Date())) {
          // console.log("refresh", tokParts.exp * 1000);
          await store.dispatch("auth/refreshToken");
        }
        // console.log(localStorage.getItem("idca"));

        const contratto = {
          id: localStorage.getItem("idca"),
        };
        if (contratto.id) {
          await store.dispatch("auth/selectContratto", contratto);

          commit("auth/initDatiLoginFromStorage", {
            token: state.auth.token,
            contrattoAttivo: state.auth.contrattoAttivo,
          });
          await dispatch("setDatiApplicazione");
          router.push({ path: "/" });
        } else {
          commit("auth/setLoginStep", LoginSteps.SELEZIONE_CONTRATTO);
        }
      }
    },
    async initFromStorage({ commit, state, dispatch }) {
      //console.group();
      //console.log("state.auth", state.auth);
      //console.groupEnd();

      if (state.auth.loginStep === LoginSteps.LOGGED_IN) {
        return true;
      }

      try {
        let loggedIn = false;
        if (state.auth.token && state.auth.contrattoAttivo) {
          // const token = tutils.read(LZString.decompressFromUTF16(state.s))[0];
          // console.log(
          //   "🚀 ~ file: index.js:184 ~ initFromStorage ~ token:",
          //   token
          // );
          // const contrattoAttivo = JSON.parse(
          //   LZString.decompressFromUTF16(state.idca)
          // );
          // console.log(
          //   "🚀 ~ file: index.js:188 ~ initFromStorage ~ contrattoAttivo:",
          //   contrattoAttivo
          // );
          commit("auth/initDatiLoginFromStorage", {
            token: state.auth.token,
            contrattoAttivo: state.auth.contrattoAttivo,
          });
          await dispatch("setDatiApplicazione");
          loggedIn = true;
        }
        return loggedIn;
      } catch (error) {
        commit("auth/initDatiLoginFromStorage", {});
        commit("setDatiApplicazione", null);

        throw new Error(`Errore inizializzazione: ${error}`);
      }
    },

    async setDatiApplicazione({ commit, dispatch }) {
      try {
        const axiosResponse = await this._vm.$api.datiIniziali();
        if (isStatusSuccess(axiosResponse.status)) {
          const appData = axiosResponse.data;
          commit("setDatiApplicazione", appData);
        } else {
          createAndSetErrorAlert(
            dispatch,
            "Errore durante la configurazione dei dati iniziali! Aggiornare la pagina per ricaricare i dati",
            null,
            true
          );
        }
      } catch ({ message }) {
        _l("store/index:159 setDatiApplicazione error");
        createAndSetErrorAlert(dispatch, message, null, true);
      }
    },
    setAxiosError({ commit }, errore) {
      if (errore) {
        commit("setAxiosError", errore);
        _l(
          "store/index:171 setAxiosError errore.response.data",
          errore.response.data
        );
        if (REGEXES.TOK_EXP.test(errore.response.data)) {
          commit("auth/resetState");
          commit("auth/setLoginStep", LoginSteps.CREDENZIALI);
          router.replace("/login");
        }
      }
    },
    resetAxiosError({ commit }) {
      commit("setAxiosError", null);
    },
    setServerError({ commit }, hasErrors = true) {
      commit("setServerError", hasErrors);
    },
    setActiveSection({ commit }, activeSection) {
      commit("setActiveSection", activeSection);
    },
    setSelectedContract({ commit }, c) {
      commit("setSelectedContract", c);
    },
    setMonthAndYearSelected({ commit }, monthAndYear) {
      commit("setMonthAndYearSelected", monthAndYear);
    },
    setAlert({ commit, getters }, alertData) {
      try {
        commit("setAlert", null);
        if (alertData) {
          const blockAlertOnLoginSection =
            getters.activeSection === "Login" && alertData.login !== true;

          if (blockAlertOnLoginSection) {
            return false;
          }
          const alert = new Alert(alertData);
          commit("setAlert", alert);
          return new Promise((resolve) => {
            if (alert.duration) {
              setTimeout(() => {
                resolve(commit("setAlert", null));
              }, alert.duration);
            } else {
              resolve(true);
            }
          });
        }
      } catch (error) {
        if (!_.isEmpty(alertData.msg)) {
          alert(alertData.msg);
        }
        commit("setAlert", null);
      }
    },
    setUpdateAvailable({ commit }, isUpdateAvailable = true) {
      commit("setUpdateAvailable", isUpdateAvailable);
    },

    setIsReloadRequired({ commit }, isReloadRequired = true) {
      commit("setIsReloadRequired", isReloadRequired);
    },
    setWindowWidth({ commit }, windowWidth) {
      commit("setWindowWidth", windowWidth);
    },
    setAvvisoGenerale({ commit }, avvisoGenerale) {
      commit("setAvvisoGenerale", avvisoGenerale);
    },

    setAziendaDatiApplicazione({ commit }, azienda) {
      commit("setAziendaDatiApplicazione", azienda);
    },
  },
  getters: {
    // OGNI GETTER RICEVE QUATTRO PARAMETRI
    // (state, getters, rootState, rootGetters)
    developmentMode: () => process.env.NODE_ENV === "development",
    activeSection: (state) => state.activeSection,
    isTimbraturaActiveSection: (state) => state.activeSection === "Timbrature",
    selectedContract: ({ selectedContract }, getters) =>
      selectedContract || getters.contratto,
    monthAndYearSelected: (state) => state.monthAndYearSelected,
    applicationData: (state) => state.applicationData,
    contrattoApplicationData: (state) => state.applicationData.contratto,
    azienda: (state) => state.applicationData.azienda,

    primoPeriodoValido: ({ primoPeriodoValido }) => primoPeriodoValido,
    ultimoPeriodoValido: ({ ultimoPeriodoValido }) => ultimoPeriodoValido,

    utente: (state) => state.applicationData.utente,
    utentiGestibili: (state) => {
      let users = [];
      state.applicationData.contrattiGestibili.forEach((c) => {
        if (users.findIndex((u) => u.cf === c.cfsoggetto) === -1) {
          users.push({
            cf: c.cfsoggetto,
            nomeSoggetto: c.nomeSoggetto,
          });
        }
      });
      return users;
    },

    authLevel: ({ applicationData }) =>
      new AuthLevel(applicationData.utente.authLevel),
    isCompanyAdmin: (state, getters) => getters.authLevel.isCompanyAdmin(),
    isAtLeastCompanyAdmin: (state, getters) =>
      getters.authLevel.isAtLeastCompanyAdmin(),

    periodoCorrente: (state) => state.applicationData.azienda.periodoCorrente,
    annoPeriodoCorrente: (state) =>
      parse(
        state.applicationData.azienda.periodoCorrente,
        DateDefaults.formats.CLIENT,
        new Date()
      ).getFullYear(),
    mesePeriodoCorrente: (state) =>
      parse(
        state.applicationData.azienda.periodoCorrente,
        DateDefaults.formats.CLIENT,
        new Date()
      ).getMonth(),
    contratto: ({ applicationData }, getters) => {
      if (applicationData.contratto) {
        return applicationData.contratto;
      }
      if (getters.authLevel.isAtLeastTaxAdvisor()) {
        return (
          getters.contrattiGestibiliOnlyDipendente[0] ||
          applicationData.contrattiGestibili[0] ||
          null
        );
      }
      return null;
    },
    tipi: (state) => ({
      elementoRetribuzione:
        state.applicationData.tipiElementoRetribuzione.sort(compare),
      evento: state.applicationData.tipiEvento.reduce(
        (tipiMap, evt) => ({ ...tipiMap, [evt.codice]: evt }),
        {}
      ),
      abilitazione: state.applicationData.tipiAbilitazione,
    }),
    tipoEvento: (_, getters) => (codice) => {
      return getters.tipi.evento[codice];
    },
    tipiEventoUsable: (state) =>
      state.applicationData.tipiEvento.filter(
        ({ usableGUI }) => usableGUI === "USER"
      ),
    tipiEventoFilteredByDate: (_, getters) => (from, to) => {
      let typesEvent = getters.tipiEventoUsable.filter(
        ({ validoDal, validoAl }) =>
          filterTypesEventByDate(validoDal, validoAl, from) &&
          filterTypesEventByDate(validoDal, validoAl, to)
      );
      typesEvent.sort((a, b) => {
        if (a.descrizione < b.descrizione) return -1;
        if (a.descrizione > b.descrizione) return 1;
        return 0;
      });
      return typesEvent;
    },
    tipiEventoFilteredByDateAndDuration:
      (_, getters) => (from, to, durationEvent) => {
        let typesEvent = getters.tipiEventoUsable.filter(
          ({ validoDal, validoAl, tipoUsoEvento }) =>
            filterTypesEventByUse(tipoUsoEvento, durationEvent) &&
            filterTypesEventByDate(validoDal, validoAl, from) &&
            filterTypesEventByDate(validoDal, validoAl, to)
        );

        typesEvent.sort((a, b) => {
          if (a.descrizione < b.descrizione) return -1;
          if (a.descrizione > b.descrizione) return 1;
          return 0;
        });
        return typesEvent;
      },

    tipiDocumento: ({ applicationData }) => applicationData.tipiDocumento,
    tipiDocumentoFormatToSelectBox: ({ applicationData }) => {
      let tipi = [];
      for (let td in applicationData.tipiDocumento) {
        tipi.push({ text: applicationData.tipiDocumento[td], value: td });
      }
      tipi.sort((a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
      });
      return tipi;
    },

    tipiCCNL: ({ applicationData }) => applicationData.tipiCCNL,
    tipiCCNLFormatToSelectBox: ({ applicationData }) => {
      let tipi = [];
      for (let td in applicationData.tipiCCNL) {
        tipi.push({ text: applicationData.tipiCCNL[td], value: td });
      }
      tipi.sort((a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
      });
      return tipi;
    },

    tipiDurataRapportoContratto: ({ applicationData }) =>
      applicationData.tipiDurataRapportoContratto,
    tipiDurataRapportoContrattoFormatToSelectBox: ({ applicationData }) => {
      let tipi = [];
      for (let td in applicationData.tipiDurataRapportoContratto) {
        tipi.push({
          text: applicationData.tipiDurataRapportoContratto[td],
          value: td,
        });
      }
      tipi.sort((a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
      });
      return tipi;
    },

    tipiCategoriaFormatToSelectBox: ({ applicationData }) => {
      let categorie = [];
      for (let cl in applicationData.categorieLavoratore) {
        categorie.push({
          text: applicationData.categorieLavoratore[cl],
          value: cl,
        });
      }
      categorie.sort((a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
      });
      return categorie;
    },

    tipiRapportoContratto: ({ applicationData }) =>
      applicationData.tipiRapportoContratto,
    tipiRapportoContrattoFormatToSelectBox: ({ applicationData }) => {
      let tipi = [];
      for (let td in applicationData.tipiRapportoContratto) {
        tipi.push({
          text: applicationData.tipiRapportoContratto[td],
          value: td,
        });
      }
      tipi.sort((a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
      });
      return tipi;
    },

    contrattiUtente: (state) => {
      return state.applicationData.contrattiGestibili.filter(
        (cg) => cg.cfsoggetto === state.applicationData.utente.cf
      );
    },
    contrattiUtenteFilteredByPeriod:
      (state, { contrattiUtente }) =>
      (from, to) => {
        const contracts = contrattiUtente.filter(
          ({ dataInizio, dataFine }) =>
            (isDateEqualOrAfter(from, new Date(dataInizio)) ||
              isSameMonth(from, new Date(dataInizio))) &&
            (!dataFine ||
              isDateEqualOrBefore(to, new Date(dataFine)) ||
              isDateEqualOrBefore(to, new Date(dataFine)))
        );
        return sortContractsByName(contracts);
      },
    contrattiUtentiFilteredByMonthAndYearSelected: (state, getters) => {
      const from = new Date(state.monthAndYearSelected);
      const to = new Date(endOfMonth(from));
      return getters.contrattiUtenteFilteredByPeriod(from, to);
    },
    contrattiUtentiFilteredByMonthAndYearSelectedOnlyDipendente: (
      state,
      getters
    ) => {
      const from = new Date(state.monthAndYearSelected);
      const to = new Date(endOfMonth(from));
      return getters
        .contrattiUtenteFilteredByPeriod(from, to)
        .filter(
          ({ tipoRapporto }) => tipoRapporto === TIPO_RAPPORTO_DIPENDENTE
        );
    },

    contrattiGestibili: (state) =>
      sortContractsByName(state.applicationData.contrattiGestibili),
    contrattiGestibiliFilteredByPeriod: (state) => (from, to) => {
      let contracts = state.applicationData.contrattiGestibili.filter(
        ({ dataInizio, dataFine }) =>
          (isDateEqualOrAfter(from, new Date(dataInizio)) ||
            isSameMonth(from, new Date(dataInizio))) &&
          (!dataFine ||
            isDateEqualOrBefore(to, new Date(dataFine)) ||
            isSameMonth(to, new Date(dataFine)))
      );
      return sortContractsByName(contracts);
    },
    contrattiGestibiliFilteredByDate: (state) => (from, to) => {
      let contracts = state.applicationData.contrattiGestibili.filter(
        ({ dataInizio, dataFine }) =>
          isDateEqualOrAfter(from, new Date(dataInizio)) &&
          (!dataFine || isDateEqualOrBefore(to, new Date(dataFine)))
      );
      return sortContractsByName(contracts);
    },
    contrattiGestibiliFilteredByMonthAndYearSelected: (state, getters) => {
      const from = new Date(state.monthAndYearSelected);
      const to = new Date(endOfMonth(from));
      return getters.contrattiGestibiliFilteredByPeriod(from, to);
    },
    contrattiGestibiliFilteredForTypeDipendente:
      (state, getters) => (from, to) => {
        return getters
          .contrattiGestibiliFilteredByDate(from, to)
          .filter(
            ({ tipoRapporto }) => tipoRapporto === TIPO_RAPPORTO_DIPENDENTE
          );
      },
    contrattiGestibiliFilteredByMonthAndYearSelectedForTypeDipendente: (
      state,
      getters
    ) => {
      const from = new Date(state.monthAndYearSelected);
      const to = new Date(endOfMonth(from));
      return getters.contrattiGestibiliFilteredForTypeDipendente(from, to);
    },
    contrattiGestibiliOnlyDipendente: (state, getters) =>
      getters.contrattiGestibili.filter(
        ({ tipoRapporto }) => tipoRapporto === TIPO_RAPPORTO_DIPENDENTE
      ),
    contrattiVisualizzabiliDaUtente: (state, getters) => {
      return getters.isAtLeastCompanyAdmin
        ? [...getters.contrattiGestibiliFilteredByMonthAndYearSelected]
        : [{ ...getters.contratto }];
    },
    contrattiConTimbrature: (state, getters) => {
      return getters.filter(({ conTimbratura }) => conTimbratura);
    },
    contrattiConTimbratureByMonthAndYearSelected: (state, getters) => {
      return getters.contrattiGestibiliFilteredByMonthAndYearSelected.filter(
        ({ conTimbratura }) => conTimbratura
      );
    },

    tipiRichieste: ({ applicationData }) => {
      if (applicationData.azienda.tipoAzienda == TipoAzienda.LIGHT) {
        //Mostro solo i tipi richieste verso il system admin
        return applicationData.tipiRichieste.filter(
          (tipo) =>
            tipo.destinatarioRichiesta == DestinatarioRichiesta.SYSTEM_ADMIN
        );
      } else {
        //Le mostro tutte
        return applicationData.tipiRichieste;
      }
    },

    isFull: ({ applicationData }) => {
      return applicationData.azienda.tipoAzienda == TipoAzienda.FULL;
    },
    tipoRichiestaFilteredByTipo: (state) => (tipo) => {
      return state.applicationData.tipiRichieste.filter(
        (x) => x.codice === tipo
      )[0];
    },

    tipoAzienda: ({ applicationData }) => applicationData.azienda.tipoAzienda,

    axiosError: (state) => state.axiosError,
    serverError: (state) => state.serverError,
    alert: (state) => state.alert,
    isMobile: (state) => state.windowWidth <= 600,
    isBrowserMobile: () =>
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Brave|Opera Mini/i.test(
        navigator.userAgent
      ),
    isUpdateAvailable: (state) => state.updateAvailable,
    isReloadRequired: (state) => state.isReloadRequired,
    avvisoGenerale: (state) => state.avvisoGenerale,
    windowWidth: (state) => state.windowWidth,
  },
  plugins: [storeHelpers],
});

function sortContractsByName(contracts) {
  if (contracts) {
    contracts.sort((a, b) => {
      if (a.nomeSoggetto < b.nomeSoggetto) return -1;
      if (a.nomeSoggetto > b.nomeSoggetto) return 1;
      return 0;
    });
  }
  return contracts;
}

function isStatusSuccess(statusCode) {
  return statusCode >= 200 && statusCode <= 299;
}
function createAndSetErrorAlert(dispatch, msg, duration, persistent) {
  _l("store/index:333 createAndSetErrorAlert", msg);
  const alert = {
    show: true,
    type: AlertType.ERROR,
    msg,
    duration,
    persistent,
  };
  setAlert(dispatch, alert);
}
function setAlert(dispatch, alert) {
  dispatch("setAlert", alert);
}

function filterTypesEventByUse(tipoUsoEvento, durationEvent) {
  return tipoUsoEvento === "ORAGIORNO" || tipoUsoEvento === durationEvent;
}

function filterTypesEventByDate(validoDal, validoAl, date) {
  return (
    (!validoDal && !validoAl) ||
    (!validoDal && validoAl && isDateEqualOrBefore(date, new Date(validoAl))) ||
    (validoDal && !validoAl && isDateEqualOrAfter(date, new Date(validoDal))) ||
    isWithinInterval(date, {
      start: new Date(validoDal),
      end: new Date(validoAl),
    })
  );
}

function isDateEqualOrAfter(date, compareDate) {
  date.setHours(0, 0, 0, 0);
  compareDate.setHours(0, 0, 0, 0);
  return isEqual(date, compareDate) || isAfter(date, compareDate);
}
function isDateEqualOrBefore(date, compareDate) {
  date.setHours(0, 0, 0, 0);
  compareDate.setHours(0, 0, 0, 0);
  return isEqual(date, compareDate) || isBefore(date, compareDate);
}

/** Ordina */
function compare(a, b) {
  if (a.descrizione < b.descrizione) {
    return -1;
  }
  if (a.descrizione > b.descrizione) {
    return 1;
  }
  return 0;
}
