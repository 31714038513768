export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      listEvents: "/userApi/evento?",
      deleteEvent: "/userApi/evento/",
      editEvent: "/userApi/evento/",
      addEvent: "/userApi/evento/",
    },

    fetchEvents(from, to) {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.listEvents}from=${from}&to=${to}`,
        needsAuth: true,
      });
    },

    deleteEvent(id) {
      return this.call({
        method: this._method.DELETE,
        url: `${this.uris.deleteEvent}${id}`,
        needsAuth: true,
      });
    },

    editEvent(event) {
      return this.call({
        method: this._method.PUT,
        url: this.uris.editEvent,
        needsAuth: true,
        data: event,
      });
    },

    acceptEventsPeriod(from, to) {
      return this.call({
        method: this._method.PATCH,
        url: `${this.uris.editEvent}?from=${from}&to=${to}`,
        needsAuth: true,
      });
    },

    addEvent(event) {
      return this.call({
        method: this._method.POST,
        url: this.uris.addEvent,
        needsAuth: true,
        data: event,
      });
    },
  };
}

