export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      richieste: "/userApi/richiesta",
    },
    addRichiesta(richiesta) {
      return this.call({
        needsAuth: true,
        method: this._method.POST,
        url: `${this.uris.richieste}`,
        data: richiesta,
      });
    },

    fetchRichieste(completate, from, to) {
      return this.call({
        needsAuth: true,
        method: this._method.GET,
        url: `${this.uris.richieste}?from=${from}&to=${to}&completed=${completate}`,
      });
    },

    addMessaggio(idRichiesta, form) {
      return this.call({
        header: {
          "Content-Type": "multipart/form-data",
        },
        method: this._method.POST,
        url: `${this.uris.richieste}/${idRichiesta}`,
        needsAuth: true,
        data: form,
      });
    },

    fetchMessaggi(idRichiesta) {
      return this.call({
        needsAuth: true,
        method: this._method.GET,
        url: `${this.uris.richieste}/${idRichiesta}/messaggi`,
      });
    },

    chiudiRichiesta(idRichiesta) {
      return this.call({
        needsAuth: true,
        method: this._method.PATCH,
        url: `${this.uris.richieste}/${idRichiesta}/chiudi`,
      });
    },

    apriRichiesta(idRichiesta) {
      return this.call({
        needsAuth: true,
        method: this._method.PATCH,
        url: `${this.uris.richieste}/${idRichiesta}/apri`,
      });
    },

    fetchAllegato(idRichiesta, idMessaggio) {
      return this.call({
        method: this._method.GET,
        responseType: "blob",
        url: `${this.uris.richieste}/${idRichiesta}/messaggi/${idMessaggio}/allegato`,
        needsAuth: true,
      });
    },
  };
}
