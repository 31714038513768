export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      presenzeMese: "/userApi/presenzeMese",
    },
    fetchAllPresenzeMese(periodo) {
      let url = `${this.uris.presenzeMese}/${periodo}`;
      return this.call({
        needsAuth: true,
        method: this._method.GET,
        url: url,
      });
    },

    fetchPresenzeMeseContratto(periodo, idContratto) {
      let url = `${this.uris.presenzeMese}/${periodo}/${idContratto}`;

      return this.call({
        needsAuth: true,
        method: this._method.GET,
        url: url,
      });
    },

    rigeneraPresenzeMeseContratto(periodo, idContratto) {
      return this.call({
        needsAuth: true,
        method: this._method.PATCH,
        url: `${this.uris.presenzeMese}/${periodo}/${idContratto}`,
      });
    },

    editPresenzeMeseContratto(presenze, periodo, idContratto) {
      return this.call({
        needsAuth: true,
        method: this._method.PUT,
        url: `${this.uris.presenzeMese}/${periodo}/${idContratto}`,
        data: presenze,
      });
    },

    editRateiFerie(ratei, periodo, idContratto) {
      return this.call({
        needsAuth: true,
        method: this._method.PUT,
        url: `${this.uris.presenzeMese}/${periodo}/${idContratto}/rateiFerie`,
        data: ratei,
      });
    },

    editRateiPermessi(ratei, periodo, idContratto) {
      return this.call({
        needsAuth: true,
        method: this._method.PUT,
        url: `${this.uris.presenzeMese}/${periodo}/${idContratto}/rateiPermessi`,
        data: ratei,
      });
    },
  };
}
