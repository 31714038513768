export const TipoRichiesta = Object.freeze({
  COMPLETATA: "COMPLETATA",
  IN_CORSO: "IN_CORSO",
  DA_PAGARE: "DA_PAGARE",
});

export const DestinatarioRichiesta = Object.freeze({
  SYSTEM_ADMIN: "SYSADMIN",
  COMPANY_ADMIN: "AZIENDA",
  TAX_ADVISOR: "CONSULENTE",

  decodificaDestinatarioRichiesta(destinatario) {
    if (destinatario == null) {
      return "nessuna richiesta selezionata";
    } else {
      if (destinatario === this.SYSTEM_ADMIN) {
        return "SUPPORTO MIDA 4";
      } else if (
        destinatario === this.TAX_ADVISOR ||
        destinatario === this.COMPANY_ADMIN
      ) {
        return destinatario;
      }
    }
  },
});

export const StatoRichiesta = Object.freeze({
  getColor(tipoRichiesta) {
    switch (tipoRichiesta) {
      case TipoRichiesta.COMPLETATA:
        return "success";
      case TipoRichiesta.IN_CORSO:
        return "warning";
      case TipoRichiesta.DA_PAGARE:
        return "error";
    }
  },

  getDescrizione(tipoRichiesta) {
    switch (tipoRichiesta) {
      case TipoRichiesta.COMPLETATA:
        return "COMPLETATA";
      case TipoRichiesta.IN_CORSO:
        return "IN CORSO";
      case TipoRichiesta.DA_PAGARE:
        return "DA PAGARE";
    }
  },
});
