import { AlertType } from "@/entities/alert.js";
import store from "@/store"; // Adjust the path based on your project structure

const state = {
  azienda: undefined,
  aziendaControllo: undefined,
  logElaborazioni: [],
  isValido: false,
  isShow: false,
};

const getters = {
  getAzienda: (state) => state.azienda,
  getAziendaControllo: (state) => state.aziendaControllo,
  getLogElaborazioni: (state) => state.logElaborazioni,
  getIsValido: (state) => state.isValido,
  getIsShow: (state) => state.isShow,
};

const actions = {
  async fetchStatoElaborazioneAzienda({ dispatch, commit }) {
    try {
      const axiosResponse =
        await this._vm.$api.admin.fetchStatoElaborazioneAzienda();
      if (isStatusSuccess(axiosResponse.status)) {
        if (axiosResponse.data != undefined) {
          // console.log(axiosResponse.data);
          commit("setAzienda", axiosResponse.data);
          await store.dispatch(
            "setAziendaDatiApplicazione",
            axiosResponse.data
          );
        }
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async fetchLogElaborazioneAzienda({ dispatch, commit }) {
    try {
      const axiosResponse =
        await this._vm.$api.admin.fetchLogElaborazioneAzienda();
      if (isStatusSuccess(axiosResponse.status)) {
        if (axiosResponse.data != undefined) {
          // console.log(axiosResponse.data);
          commit("setLogElaborazioni", axiosResponse.data);
        }
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async controllaStatoElaborazioneAzienda({ state, dispatch, commit }) {
    try {
      const axiosResponse =
        await this._vm.$api.admin.fetchStatoElaborazioneAzienda();
      if (isStatusSuccess(axiosResponse.status)) {
        if (axiosResponse.data != undefined) {
          const aziendaControllo = axiosResponse.data;
          commit("setAziendaControllo", aziendaControllo);
          if (
            aziendaControllo.periodoCorrente != state.azienda.periodoCorrente ||
            aziendaControllo.statoElaborazione !=
              state.azienda.statoElaborazione
          ) {
            commit("setIsValido", false);
            createAndSetWarningAlert(
              dispatch,
              "Attenzione: fase o periodo di elaborazione errato, reindirizzamento alla pagina corretta."
            );
          } else if (aziendaControllo.busyElaborazione) {
            commit("setIsValido", false);
            createAndSetWarningAlert(dispatch, "Elaborazione in corso...");
          } else {
            commit("setIsValido", true);
          }
        }
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async avantiElaborazione({ commit, dispatch }) {
    try {
      const axiosResponse = await this._vm.$api.admin.avanti();
      if (isStatusSuccess(axiosResponse.status)) {
        // console.log("avanti", axiosResponse.data);
        commit("setAzienda", axiosResponse.data);
        // createAndSetSuccessAlert(
        //   dispatch,
        //   true,
        //   "Elaborazione avviata con successo"
        // );
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async indietroElaborazione({ commit, dispatch }) {
    try {
      const axiosResponse = await this._vm.$api.admin.indietro();
      if (isStatusSuccess(axiosResponse.status)) {
        // console.log("indietro", axiosResponse.data);
        commit("setAzienda", axiosResponse.data);
        // createAndSetSuccessAlert(
        //   dispatch,
        //   true,
        //   "Elaborazione avviata con successo"
        // );
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  mostraLogElaborazioni({ commit }, value) {
    commit("setIsShow", value);
  },
};

const mutations = {
  setAzienda(state, azienda) {
    state.azienda = azienda;
  },
  setAziendaControllo(state, aziendaControllo) {
    state.aziendaControllo = aziendaControllo;
  },
  setLogElaborazioni(state, logElaborazioni) {
    state.logElaborazioni = logElaborazioni;
  },
  setIsValido(state, isValido) {
    state.isValido = isValido;
  },
  setIsShow(state, isShow) {
    state.isShow = isShow;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

function isStatusSuccess(statusCode) {
  return statusCode >= 200 && statusCode <= 299;
}

// function createAndSetSuccessAlert(dispatch, show, msg, duration) {
//   const alert = {
//     show,
//     type: AlertType.SUCCESS,
//     msg,
//     duration,
//   };
//   setAlert(dispatch, alert);
// }
function createAndSetErrorAlert(dispatch, msg, duration) {
  const alert = {
    show: true,
    type: AlertType.ERROR,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}

function createAndSetWarningAlert(dispatch, msg, duration) {
  const alert = {
    show: true,
    type: AlertType.WARNING,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}
function setAlert(dispatch, alert) {
  dispatch("setAlert", alert, { root: true });
}
