import { AlertType } from "@/entities/alert.js";
import store from "@/store"; // Adjust the path based on your project structure

const state = {
  aziendaSelezionata: {},
};

const getters = {
  aziendaSelezionata: ({ aziendaSelezionata }) => aziendaSelezionata,
};

const actions = {
  async fetchAzienda({ dispatch }, { idAzienda }) {
    try {
      const axiosResponse = await this._vm.$api.admin.fetchAzienda(idAzienda);
      if (isStatusSuccess(axiosResponse.status)) {
        dispatch("setAziendaSelezionata", axiosResponse.data);
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },
  async modificaAzienda({ commit, dispatch }, { azienda }) {
    try {
      // console.log("modificaAzienda", { azienda });

      if (azienda.consulente) {
        delete azienda.consulente.cognomeNome;
        delete azienda.consulente.authLevel;
        delete azienda.consulente.primoLoginFatto;
      }

      delete azienda.periodoMinimoElabPresenze;

      const axiosResponse = await this._vm.$api.admin.editAzienda(azienda);

      if (isStatusSuccess(axiosResponse.status)) {
        azienda = axiosResponse.data;

        commit("setAziendaSelezionata", azienda);
        createAndSetSuccessAlert(dispatch, false, "Azienda modificata");
        await store.dispatch("setAziendaDatiApplicazione", axiosResponse.data);
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },
  async setAziendaSelezionata({ commit }, value) {
    commit("setAziendaSelezionata", aggiungiProprietaAzienda(value));
  },
};

const mutations = {
  setAziendaSelezionata: (state, aziendaSelezionata) =>
    (state.aziendaSelezionata = aziendaSelezionata),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

function aggiungiProprietaAzienda(azienda) {
  return {
    ...azienda,
    residuoMassimoFerie: azienda.residuoMassimoFerie * 100,
    residuoMassimoROL: azienda.residuoMassimoROL * 100,
  };
}

function createAndSetSuccessAlert(dispatch, show, msg) {
  const alert = {
    show,
    type: AlertType.SUCCESS,
    msg,
    duration: 1000,
  };
  setAlert(dispatch, alert);
}

function isStatusSuccess(statusCode) {
  return statusCode >= 200 && statusCode <= 299;
}

function createAndSetErrorAlert(dispatch, msg, duration) {
  const alert = {
    show: true,
    type: AlertType.ERROR,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}

function setAlert(dispatch, alert) {
  dispatch("setAlert", alert, { root: true });
}
