<template>
  <div id="app">
    <LoaderMida :showLoader="true" />
    <router-view />
  </div>
</template>

<script>
import LoaderMida from "@/components/_common/LoaderMida";
import { mapActions, mapGetters } from "vuex";
import { _err } from "@/entities/log";
import { AlertType } from "@/entities/alert";
import { checkVersion } from "@/checkVersion";
import conf from "@/../package.json";
const UPDATE_CHECK_TIMER = 3 * 60 * 60 * 1000;
const CURRENT_VERSION = conf.version;
const channel = new BroadcastChannel("update-messages");

export default {
  components: {
    LoaderMida,
  },

  async created() {
    channel.onmessage = (msg) => {
      if (msg && msg.data === "UPDATE_AVAILABLE") {
        this.updateAvailable();
      }
    };

    // prima controllo se ci sono aggiornamenti all'accesso
    await this.checkUpdates();
    // poi aggiungo l'evento
    document.addEventListener("checkUpdates", this.checkUpdates);

    window.addEventListener("resize", this.setWindowWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.setWindowWidth);
  },

  methods: {
    ...mapActions({
      setAlert: "setAlert",
      setWindowWidthPropertyVuex: "setWindowWidth",
      setAxiosError: "setAxiosError",
      setUpdateAvailable: "setUpdateAvailable",
    }),
    checkUpdatesLoop() {
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent("checkUpdates"));
        this.checkUpdatesLoop();
      }, UPDATE_CHECK_TIMER);
    },

    async checkUpdates() {
      if ((await checkVersion(CURRENT_VERSION)) && !this.developmentMode) {
        channel.postMessage("UPDATE_AVAILABLE");
        this.updateAvailable();
      }
    },

    updateAvailable() {
      this.$nextTick(async () => {
        await this.setAlert({
          type: AlertType.INFO,
          msg: "Rilevata nuova versione: aggiornamento in corso...",
          duration: 3 * 1000,
          persistent: true,
          login: true,
        });
        location.reload(true);
      });
    },
    setWindowWidth() {
      this.setWindowWidthPropertyVuex(window.innerWidth);
    },
  },

  computed: {
    ...mapGetters({
      developmentMode: "developmentMode",
    }),
  },

  watch: {
    "$api.errore"(err) {
      this.setAxiosError(_err("watch $api.errore", err));
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  .main-section {
    margin: 0px 15px;
  }

  .container {
    max-width: none;
    .row {
      margin: 0;
      .col {
        padding: 0;
      }
    }
  }

  .v-data-table {
    border: 1px solid #cad7e9;
    margin-top: 12px;

    tbody {
      tr:not(.v-data-table__empty-wrapper) {
        cursor: pointer;
      }
    }
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr
    > th {
    color: rgba(0, 0, 0, 0.87);
  }

  .v-dialog {
    margin: 0;
    padding: 0;
    max-width: 500px;
    overflow: visible;

    header {
      .v-toolbar__content {
        padding: 2px 16px;
      }
    }
  }

  .v-card {
    .v-card__subtitle,
    .v-card__text,
    .v-card__title {
      padding: 4px 4px;
    }

    .v-card__text {
      padding-bottom: 9px;
    }
    .v-card__actions {
      padding-top: 0;
    }
    .container {
      overflow-y: auto;
      padding: 0;

      .col-xl,
      .col-xl-auto,
      .col-xl-12,
      .col-xl-11,
      .col-xl-10,
      .col-xl-9,
      .col-xl-8,
      .col-xl-7,
      .col-xl-6,
      .col-xl-5,
      .col-xl-4,
      .col-xl-3,
      .col-xl-2,
      .col-xl-1,
      .col-lg,
      .col-lg-auto,
      .col-lg-12,
      .col-lg-11,
      .col-lg-10,
      .col-lg-9,
      .col-lg-8,
      .col-lg-7,
      .col-lg-6,
      .col-lg-5,
      .col-lg-4,
      .col-lg-3,
      .col-lg-2,
      .col-lg-1,
      .col-md,
      .col-md-auto,
      .col-md-12,
      .col-md-11,
      .col-md-10,
      .col-md-9,
      .col-md-8,
      .col-md-7,
      .col-md-6,
      .col-md-5,
      .col-md-4,
      .col-md-3,
      .col-md-2,
      .col-md-1,
      .col-sm,
      .col-sm-auto,
      .col-sm-12,
      .col-sm-11,
      .col-sm-10,
      .col-sm-9,
      .col-sm-8,
      .col-sm-7,
      .col-sm-6,
      .col-sm-5,
      .col-sm-4,
      .col-sm-3,
      .col-sm-2,
      .col-sm-1,
      .col,
      .col-auto,
      .col-12,
      .col-11,
      .col-10,
      .col-9,
      .col-8,
      .col-7,
      .col-6,
      .col-5,
      .col-4,
      .col-3,
      .col-2,
      .col-1 {
        width: 100%;
        padding: 4px 12px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .v-text-field.v-text-field--solo .v-input__control {
      min-height: 0;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
      > .v-input__control
      > .v-input__slot {
      padding: 0;
    }
    .v-input__slot {
      margin-bottom: 0px;
    }

    .v-text-field__details {
      display: none;
    }
    .v-messages {
      display: none;
    }
    .mostra-messaggio-errore.error--text {
      .v-text-field__details {
        display: initial;
        margin-top: 0.2rem;
      }
      div.v-messages {
        display: initial;
      }
    }
  }

  div input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }

  .v-btn {
    color: var(--color-button-default);
  }
  .theme--dark.v-btn,
  .theme--dark.v-icon {
    color: var(--color-icon-theme-dark);
  }
}

@media screen and (max-width: 600px) {
  .main-section {
    margin: 0 !important;
  }
}
</style>
