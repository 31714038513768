export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      rimborsi: "/userApi/elementoRetribuzione/",
    },

    fetchRimborsi(from, to, idContratto) {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.rimborsi}${idContratto}?from=${from}&to=${to}`,
        needsAuth: true,
      });
    },

    getStatsMese(from, to) {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.rimborsi}stats?from=${from}&to=${to}`,
        needsAuth: true,
      });
    },

    accettaRimborsiMese(idContratto, dateFrom, dateTo) {
      return this.call({
        method: this._method.PATCH,
        url: `${this.uris.rimborsi}${idContratto}?from=${dateFrom}&to=${dateTo}`,
        needsAuth: true,
      });
    },

    deleteRimborso(rimborso, idContratto) {
      return this.call({
        method: this._method.DELETE,
        url: `${this.uris.rimborsi}${idContratto}/${rimborso.id}`,
        needsAuth: true,
        data: rimborso,
      });
    },

    editRimborso(rimborso, idContratto) {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.rimborsi}${idContratto}`,
        needsAuth: true,
        data: rimborso,
      });
    },

    addRimborso(rimborso, idContratto) {
      return this.call({
        method: this._method.POST,
        url: `${this.uris.rimborsi}${idContratto}`,
        needsAuth: true,
        data: rimborso,
      });
    },

    addAllegato(idRimborso, idContratto, attachment) {
      return this.call({
        header: {
          "Content-Type": "multipart/form-data",
        },
        method: this._method.POST,
        url: `${this.uris.rimborsi}${idContratto}/${idRimborso}/attachment`,
        needsAuth: true,
        data: attachment,
      });
    },

    getAllegato(idRimborso, idContratto) {
      return this.call({
        method: this._method.GET,
        responseType: "blob",
        url: `${this.uris.rimborsi}${idContratto}/${idRimborso}/attachment`,
        needsAuth: true,
      });
    },

    getEuroPerKM(idContratto) {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.rimborsi}${idContratto}/rimborsoPerKM`,
        needsAuth: true,
      });
    },
  };
}
