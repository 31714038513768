import Vue from "vue";
import VueParticles from "vue-particles";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import api from "./api";
import VueCurrencyInput from "vue-currency-input";
import helpers from "@/utils/helpers";
import router from "./router";
import mixin from "./mixins/global.js";
import { Fragment } from "vue-frag";
import { VTextField } from "vuetify/lib"; //Globally import VTextField
import VueExcelXlsx from "vue-excel-xlsx";

Vue.mixin(mixin);

Vue.use(VueExcelXlsx);
Vue.use(VueParticles);
Vue.component("FragmentMida", Fragment);
Vue.component("v-text-field", VTextField);
Vue.use(VueCurrencyInput);

Vue.use(api);
Vue.config.productionTip = false;
Vue.config.performance = true;
Vue.config.devtools = process.env.NODE_ENV == "development";

export const bus = new Vue();

window.loader = false;
window.hasShownLoaderFirstTime = false;

Vue.prototype.toggleLoader = (show) =>
  bus.$emit(show ? "showLoader" : "hideLoader");
Vue.prototype.helpers = helpers;
new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
