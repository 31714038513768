const subjectCommon = Object.freeze({
  setNameToShowUser(contratto, contrattiGestibili) {
    const contractsFound = contrattiGestibili.filter(
      (cg) => cg.cfsoggetto === contratto.cfsoggetto
    );
    if (contractsFound.length > 1) {
      const _contr = contrattiGestibili.find((cu) => cu.id === contratto.id);
      return `${_contr.nomeSoggetto} - ${_contr.mansione}`;
    }
    return contratto.nomeSoggetto;
  },
});

export default subjectCommon;

