import { startOfMonth, endOfMonth, format } from "date-fns";
import DateDefaults from "@/entities/date-defaults";
import { AlertType } from "@/entities/alert.js";
import DateUtils from "@/entities/date-utils.js";
import { Attachment } from "@/entities/attachment";
import { _copyObject } from "@/entities/funzioni-comuni";
import StateEvent from "@/entities/state-event.js";

const state = {
  rimborsi: [],
  rimborsiConteggio: [],
  rimborsoTemp: [],
  rimborsoDefault: {},
  statusOperation: null,
  importoRimborsoKM: 0,
};

function addPropertiesToArray(list) {
  const elementsModiefied = list.map((element) => {
    const newElement = {
      ...element,
      attachment:
        element.nomeAllegato == null
          ? null
          : Attachment.buildAttachmentByName(element.nomeAllegato),
    };
    return newElement;
  });

  return elementsModiefied;
}

const getters = {
  getRimborsi: (state) => state.rimborsi,
  getRimborsiConteggio: (state) => state.rimborsiConteggio,
  getRimborsoTemp: (state) => state.rimborsoTemp,
  getRimborsoDefault: (state) => state.rimborsoDefault,
  getImportoRimborsoKM: (state) => state.importoRimborsoKM,
  statusOperation: (state) => state.statusOperation,
};

const actions = {
  async fetchDocument({ dispatch }, { rimborsoSelezionato, idContratto }) {
    try {
      const axiosResponse = await this._vm.$api.rimborsi.getAllegato(
        rimborsoSelezionato.id,
        idContratto
      );
      if (isStatusSuccess(axiosResponse.status)) {
        // console.log(URL.createObjectURL(axiosResponse.data));
        return await URL.createObjectURL(axiosResponse.data);
      } else {
        creaAlertErrore(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      creaAlertErrore(dispatch, error);
    }
  },
  async aggiungiRiga(
    { rootGetters, commit, dispatch },
    { rimborsoSelezionato, idContratto, attachment }
  ) {
    try {
      const axiosResponse = await this._vm.$api.rimborsi.addRimborso(
        rimborsoSelezionato,
        idContratto
      );

      if (isStatusSuccess(axiosResponse.status)) {
        rimborsoSelezionato = axiosResponse.data;
        var axiosResponse2 = null;

        if (attachment != null) {
          var bodyFormData = new FormData();
          bodyFormData.append("file", attachment.file);
          axiosResponse2 = await this._vm.$api.rimborsi.addAllegato(
            axiosResponse.data.id,
            idContratto,
            bodyFormData
          );
        }
        if (axiosResponse2 == null || isStatusSuccess(axiosResponse2.status)) {
          rimborsoSelezionato.attachment = attachment;
          rimborsoSelezionato.nomeAllegato =
            attachment == null ? null : attachment.name;
          //Lo aggiungo alla lista
          commit("addRimborsoToList", rimborsoSelezionato);
          const listaTipi = _copyObject(rootGetters.tipi.elementoRetribuzione);
          //Setto un rimborso default per la sessione
          let rimborsoDefault = _copyObject(rimborsoSelezionato);
          rimborsoDefault.id = -1;
          rimborsoDefault.importo = 0;
          rimborsoDefault.codiceElementoRetribuzione = listaTipi.filter(
            (tipo) => tipo.codice == rimborsoDefault.codiceElementoRetribuzione
          )[0];
          rimborsoDefault.quando = new Date();
          rimborsoDefault.isNew = true;
          rimborsoDefault.annotazione = null;
          rimborsoDefault.created = null;
          rimborsoDefault.createdBy = null;
          rimborsoDefault.lastMod = null;
          rimborsoDefault.lastModBy = null;
          rimborsoDefault.tag = 0;
          rimborsoDefault.contratto = _copyObject(rootGetters.selectedContract);
          rimborsoDefault.stato = StateEvent.defaultState(
            rootGetters.isAtLeastCompanyAdmin
          ).name;
          delete rimborsoDefault.allegatoPresente;
          delete rimborsoDefault.attachment;
          delete rimborsoDefault.nomeAllegato;

          commit("setRimborsoDefault", rimborsoDefault);
          createAndSetSuccessAlert(
            dispatch,
            true,
            "Rimborso aggiunto con successo"
          );
        }
      } else {
        creaAlertErrore(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      creaAlertErrore(dispatch, error);
    }
  },

  async modificaRiga(
    { dispatch, commit },
    { rimborsoSelezionato, idContratto, attachment }
  ) {
    try {
      const axiosResponse = await this._vm.$api.rimborsi.editRimborso(
        rimborsoSelezionato,
        idContratto
      );
      if (isStatusSuccess(axiosResponse.status)) {
        rimborsoSelezionato = axiosResponse.data;
        rimborsoSelezionato.attachment = attachment;
        rimborsoSelezionato.nomeAllegato =
          attachment == null ? null : attachment.name;
        if (attachment != null) {
          var bodyFormData = new FormData();
          bodyFormData.append("file", attachment.file);

          await this._vm.$api.rimborsi.addAllegato(
            rimborsoSelezionato.id,
            idContratto,
            bodyFormData
          );
        }
        // console.log(rimborsoSelezionato.attachment);
        //Lo aggiungo alla lista
        commit("modRimborsoInList", rimborsoSelezionato);
        dispatch("selezionaTempRimborso", rimborsoSelezionato);

        createAndSetSuccessAlert(
          dispatch,
          true,
          "Rimborso modificato con successo"
        );
      } else {
        creaAlertErrore(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      creaAlertErrore(dispatch, error);
    }
  },

  async accettaRimborsiMese(
    { rootGetters, getters, commit, dispatch },
    periodo
  ) {
    try {
      const periodoSelezionato = new Date(
        periodo.anno,
        parseInt(periodo.mese, 10) - 1,
        1
      );
      const axiosResponse = await this._vm.$api.rimborsi.accettaRimborsiMese(
        rootGetters.selectedContract.id,
        estraiDataFromMeseSelezionato(periodoSelezionato),
        estraiDataToMeseSelezionato(periodoSelezionato)
      );
      if (isStatusSuccess(axiosResponse.status)) {
        await accettaRimborsi(commit, getters.getRimborsi);
        createAndSetSuccessAlert(
          dispatch,
          false,
          "Rimborsi approvati con successo"
        );
      } else {
        creaAlertErrore(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      creaAlertErrore(dispatch, message);
    }
  },

  async cancellaRiga(
    { commit, dispatch },
    { rimborsoSelezionato, idContratto }
  ) {
    try {
      const axiosResponse = await this._vm.$api.rimborsi.deleteRimborso(
        rimborsoSelezionato,
        idContratto
      );
      if (isStatusSuccess(axiosResponse.status)) {
        //Lo cancello dalla lista
        commit("delRimborsoInList", rimborsoSelezionato);

        createAndSetSuccessAlert(
          dispatch,
          true,
          "Rimborso cancellato con successo"
        );
      } else {
        creaAlertErrore(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      creaAlertErrore(dispatch, error);
    }
  },

  async recuperaCostoKM({ commit, rootGetters, dispatch }) {
    const axiosResponse = await this._vm.$api.rimborsi.getEuroPerKM(
      rootGetters.selectedContract.id
    );
    if (isStatusSuccess(axiosResponse.status)) {
      commit("setImportoRimborsoKM", axiosResponse.data);
    } else {
      creaAlertErrore(dispatch, axiosResponse.response.data);
      commit("setImportoRimborsoKM", 0);
    }
  },

  async contaRimborsiMese({ rootGetters, commit, dispatch }, periodo) {
    const mese = periodo.mese;
    const anno = periodo.anno;
    const from = format(
      startOfMonth(new Date(anno, mese - 1, 1)),
      DateDefaults.formats.CLIENT
    );
    const to = format(
      endOfMonth(new Date(anno, mese - 1, 1)),
      DateDefaults.formats.CLIENT
    );

    const axiosResponse = await this._vm.$api.rimborsi.getStatsMese(from, to);
    if (isStatusSuccess(axiosResponse.status)) {
      // Mi arriva un hashmap <idContratto, numeroRimborsi>
      // Devo collegare l'id al contratto
      const contrattiConRimborsi = rootGetters.contrattiVisualizzabiliDaUtente
        .map((contratto) => {
          const idContratto = contratto.id;
          // Se l'id del contratto non è presente, impostiamo il numero di rimborsi a 0
          const numeroRimborsi = axiosResponse.data[idContratto] || 0;

          return {
            contratto: contratto,
            numeroRimborsi: numeroRimborsi,
          };
        })
        .filter((item) => item.numeroRimborsi !== 0);
      // console.log(contrattiConRimborsi);
      commit("setRimborsiConteggio", contrattiConRimborsi);
    } else {
      creaAlertErrore(dispatch, axiosResponse.response.data);
      commit("setRimborsiConteggio", []);
    }
  },

  async fetchRimborsi({ commit, rootGetters, dispatch }, periodo) {
    //Devo avere già i rimborsi del periodo
    const mese = periodo.mese;
    const anno = periodo.anno;
    const from = format(
      startOfMonth(new Date(anno, mese - 1, 1)),
      DateDefaults.formats.CLIENT
    );
    const to = format(
      endOfMonth(new Date(anno, mese - 1, 1)),
      DateDefaults.formats.CLIENT
    );
    const axiosResponse = await this._vm.$api.rimborsi.fetchRimborsi(
      from,
      to,
      rootGetters.selectedContract.id
    );
    if (isStatusSuccess(axiosResponse.status)) {
      commit("setRimborsiList", addPropertiesToArray(axiosResponse.data));
    } else {
      creaAlertErrore(dispatch, axiosResponse.response.data);
      commit("setRimborsiList", []);
    }
  },

  async selezionaTempRimborso({ commit }, value) {
    commit("setTempRimborso", value);
  },

  setUser({ commit }, user) {
    commit("setUser", user);
  },
  setRimborsiConteggio({ commit }, rimborsiConteggio) {
    commit("setRimborsiConteggio", _copyObject(rimborsiConteggio));
  },
  setRimborsoDefault({ commit }, rimborsoDefault) {
    commit("setRimborsoDefault", _copyObject(rimborsoDefault));
  },
  setStatusOperation({ commit }, status) {
    commit("setStatusOperation", status);
  },
};

function isStatusSuccess(statusCode) {
  return statusCode >= 200 && statusCode <= 299;
}

function accettaRimborsi(commit, rimborsi) {
  rimborsi.forEach((rimborso) => {
    if (rimborso.stato == "PENDENTE") {
      rimborso.stato = "APPROVATO";
    }
  });
  commit("setRimborsiList", rimborsi);
}

function estraiDataFromMeseSelezionato(month) {
  return DateUtils.formatDateToTypeClient(month);
}
function estraiDataToMeseSelezionato(month) {
  return DateUtils.formatDateToTypeClient(endOfMonth(month, 1));
}

function creaAlertErrore(dispatch, msg, duration) {
  const alert = {
    show: true,
    type: AlertType.ERROR,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}

function createAndSetSuccessAlert(dispatch, show, msg, duration) {
  const alert = {
    show,
    type: AlertType.SUCCESS,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}

function setAlert(dispatch, alert) {
  dispatch("setAlert", alert, { root: true });
}

const mutations = {
  modRimborsoInList(state, rimb) {
    const modList = state.rimborsi.map(function modificaRimborso(rimborso) {
      if (rimborso.id == rimb.id) {
        return {
          ...rimb,
          attachment:
            rimb.nomeAllegato === null
              ? null
              : Attachment.buildAttachmentByName(rimb.nomeAllegato),
        };
      } else {
        return rimborso;
      }
    });
    state.rimborsi = modList;
  },
  delRimborsoInList(state, rimb) {
    const modList = state.rimborsi.filter((rimborso) => rimborso.id != rimb.id);
    state.rimborsi = modList;
  },
  addRimborsoToList(state, rimb) {
    state.rimborsi.push(rimb);
  },

  setImportoRimborsoKM(state, importoRimborsoKM) {
    state.importoRimborsoKM = importoRimborsoKM;
  },

  setRimborsiList(state, rimborsiList) {
    // console.log("setRimborsiList", rimborsiList);
    state.rimborsi = rimborsiList;
  },
  setRimborsiConteggio(state, rimborsiConteggio) {
    state.rimborsiConteggio = rimborsiConteggio;
  },
  setRimborsoDefault(state, rimborsoDefault) {
    state.rimborsoDefault = _copyObject(rimborsoDefault);
  },
  setTempRimborso(state, value) {
    state.rimborsoTemp = value;
  },

  setStatusOperation: (state, status) => {
    state.statusOperation = status;

    if (!!state.statusOperation && state.statusOperation.status === "success") {
      setTimeout(
        () =>
          (state.statusOperation = {
            status: "",
            message: "",
          }),
        1000
      );
    } else if (
      !!state.statusOperation &&
      state.statusOperation.status === "error"
    ) {
      setTimeout(
        () =>
          (state.statusOperation = {
            status: "",
            message: "",
          }),
        3000
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
