const state = {
  isShow: false,
  isShowDettaglio: false,
  isShowDettaglioAzienda: false,
};

const getters = {
  getIsShow: (state) => state.isShow,
  getIsShowDettaglio: (state) => state.isShowDettaglio,
  getIsShowDettaglioAzienda: (state) => state.isShowDettaglioAzienda,
};

const actions = {
  async mostraRiga({ commit }, value) {
    commit("setIsShow", value);
  },
  async mostraRigaDettaglio({ commit }, value) {
    commit("setIsShowDettaglio", value);
  },
  async mostraDettaglioAzienda({ commit }, value) {
    commit("setIsShowDettaglioAzienda", value);
  },
};

const mutations = {
  setIsShow(state, value) {
    state.isShow = value;
  },
  setIsShowDettaglio(state, value) {
    state.isShowDettaglio = value;
  },
  setIsShowDettaglioAzienda(state, value) {
    state.isShowDettaglioAzienda = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
