const state = {
  isShow: false,
  isShowStats: false,
};

const getters = {
  getIsShow: (state) => state.isShow,
  getIsShowStats: (state) => state.isShowStats,
};

const actions = {
  async mostraRiga({ commit }, value) {
    commit("setIsShow", value);
    if (!value) {
      commit("rimborsiBase/setTempRimborso", [], { root: true });
    }
  },
  mostraStatsRimborsi({ commit }, value) {
    commit("setIsShowStats", value);
  },
};

const mutations = {
  setIsShow(state, value) {
    state.isShow = value;
  },
  setIsShowStats(state, value) {
    state.isShowStats = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
