import { AlertType } from "@/entities/alert.js";
import store from "@/store";

function modificaInLista(objConfronto, objModificato) {
  if (objConfronto.id == objModificato.id) {
    return objModificato;
  } else {
    return objConfronto;
  }
}

const state = {
  utenti: [],
  utenteSelezionato: {},
  contratti: [],
  contrattoSelezionato: {},
};

const getters = {
  utenti: ({ utenti }) => utenti,
  utenteSelezionato: ({ utenteSelezionato }) => utenteSelezionato,

  contratti: ({ contratti }) => contratti,
  contrattoSelezionato: ({ contrattoSelezionato }) => contrattoSelezionato,
};

const actions = {
  async forzaLogoff({ commit, dispatch }, { utente }) {
    delete utente.primoLoginFatto;
    try {
      const axiosResponse = await this._vm.$api.utenti.forzaLogoff(utente);

      if (isStatusSuccess(axiosResponse.status)) {
        utente = axiosResponse.data;
        //Lo aggiungo alla lista
        commit("modUtenteInList", utente);
        commit("setUtenteSelezionato", utente);
        createAndSetSuccessAlert(
          dispatch,
          true,
          "Logout utente forzato con successo"
        );
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async inviaMailAttivazione({ dispatch }, { utente }) {
    delete utente.primoLoginFatto;
    try {
      const axiosResponse = await this._vm.$api.utenti.inviaMailAttivazione(
        utente
      );

      if (isStatusSuccess(axiosResponse.status)) {
        createAndSetSuccessAlert(
          dispatch,
          true,
          `Mail di attivazione inviata a ${utente.nomeCognome} (${utente.email})`
        );
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async fetchUtenti({ commit, dispatch, rootGetters }) {
    try {
      const axiosResponse = await this._vm.$api.utenti.fetchUtenti(
        rootGetters.azienda.id
      );
      if (isStatusSuccess(axiosResponse.status)) {
        commit("setUtenti", axiosResponse.data);
      } else {
        commit("setUtenti", []);
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      commit("setUtenti", []);
      createAndSetErrorAlert(dispatch, message);
    }
  },

  fetchContratti({ commit, rootGetters, state }) {
    // console.log(rootGetters.contrattiGestibili, state.utenteSelezionato?.cf);
    const contratti = rootGetters.contrattiGestibili.filter(
      (contratto) => contratto.cfsoggetto === state.utenteSelezionato?.cf
    );

    // console.log("contratti",contratti);
    commit("setContratti", contratti);
  },

  async aggiungiRiga({ commit, dispatch }, { utente }) {
    try {
      const axiosResponse = await this._vm.$api.utenti.addUtente(utente);

      if (isStatusSuccess(axiosResponse.status)) {
        utente = axiosResponse.data;
        //Lo aggiungo alla lista
        commit("addUtenteToList", utente);
        commit("setUtenteSelezionato", utente);
        await store.dispatch("setDatiApplicazione");
        createAndSetSuccessAlert(dispatch, true, "Utente aggiunto");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async cancellaRiga({ commit, dispatch }, utente) {
    try {
      const axiosResponse = await this._vm.$api.utenti.deleteUtente(utente.id);

      if (isStatusSuccess(axiosResponse.status)) {
        //Lo cancello dalla lista
        commit("delUtenteInList", utente);
        await store.dispatch("setDatiApplicazione");
        createAndSetSuccessAlert(dispatch, true, "Utente eliminato");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async modificaRiga({ commit, dispatch }, { utente }) {
    try {
      // console.log(modificaRiga,{ idAzienda, utente });
      delete utente.authLevel;
      delete utente.cognomeNome;
      delete utente.primoLoginFatto;

      const axiosResponse = await this._vm.$api.utenti.editUtente(utente);

      if (isStatusSuccess(axiosResponse.status)) {
        utente = axiosResponse.data;
        //Lo aggiungo alla lista
        commit("modUtenteInList", utente);

        commit("setUtenteSelezionato", utente);

        await store.dispatch("setDatiApplicazione");
        createAndSetSuccessAlert(dispatch, true, "Utente modificato");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async aggiungiRigaContratto({ commit, dispatch, state }, { contratto }) {
    try {
      const axiosResponse = await this._vm.$api.datiContratto.addContratto(
        state.utenteSelezionato.id,
        contratto
      );

      if (isStatusSuccess(axiosResponse.status)) {
        contratto = axiosResponse.data;
        //Lo aggiungo alla lista
        commit("addContrattoToList", contratto);
        await store.dispatch("setDatiApplicazione");
        createAndSetSuccessAlert(dispatch, true, "Contratto creato");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async cancellaRigaContratto({ commit, dispatch }, { contratto }) {
    try {
      const axiosResponse = await this._vm.$api.datiContratto.deleteContratto(
        contratto.id
      );

      if (isStatusSuccess(axiosResponse.status)) {
        //Lo cancello dalla lista
        commit("delContrattoToList", contratto);
        await store.dispatch("setDatiApplicazione");
        createAndSetSuccessAlert(dispatch, true, "Contratto eliminato");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async modificaRigaContratto({ commit, dispatch }, { contratto }) {
    try {
      const axiosResponse = await this._vm.$api.datiContratto.editContratto(
        contratto
      );

      if (isStatusSuccess(axiosResponse.status)) {
        contratto = axiosResponse.data;
        //Lo aggiungo alla lista
        commit("modContrattoToList", contratto);
        commit("setContrattoSelezionato", contratto);
        await store.dispatch("setDatiApplicazione");
        createAndSetSuccessAlert(dispatch, true, "Contratto modificato");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      createAndSetErrorAlert(dispatch, error);
    }
  },

  async setUtenteSelezionato({ commit }, value) {
    commit("setUtenteSelezionato", value);
  },

  async setContrattoSelezionato({ commit }, value) {
    commit("setContrattoSelezionato", value);
  },
};

const mutations = {
  setUtenti: (state, utenti) => (state.utenti = utenti),
  setUtenteSelezionato: (state, utenteSelezionato) =>
    (state.utenteSelezionato = utenteSelezionato),
  addUtenteToList(state, ut) {
    state.utenti.push(ut);
  },
  modUtenteInList(state, ut) {
    state.utenti = state.utenti.map((e) => modificaInLista(e, ut));
  },
  delUtenteInList(state, ut) {
    state.utenti = state.utenti.filter((utente) => utente.id != ut.id);
  },

  setContratti: (state, contratti) => (state.contratti = contratti),
  setContrattoSelezionato: (state, contrattoSelezionato) =>
    (state.contrattoSelezionato = contrattoSelezionato),
  addContrattoToList(state, ct) {
    state.utenti = state.utenti.map((e) => modificaUtenti(e, ct));
    state.contratti.push(ct);
  },
  modContrattoToList(state, ct) {
    state.contratti = state.contratti.map((e) => modificaInLista(e, ct));
  },
  delContrattoToList(state, ct) {
    state.contratti = state.contratti.filter(
      (contratto) => contratto.id != ct.id
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

function modificaUtenti(utenteConfronto, utenteModificato) {
  if (utenteConfronto.id == utenteModificato.id) {
    return utenteModificato;
  } else {
    return utenteConfronto;
  }
}

function isStatusSuccess(statusCode) {
  return statusCode >= 200 && statusCode <= 299;
}

function createAndSetSuccessAlert(dispatch, show, msg, duration) {
  const alert = {
    show,
    type: AlertType.SUCCESS,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}

function createAndSetErrorAlert(dispatch, msg, duration) {
  const alert = {
    show: true,
    type: AlertType.ERROR,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}
function setAlert(dispatch, alert) {
  dispatch("setAlert", alert, { root: true });
}
