import { startOfMonth, endOfMonth, format } from "date-fns";
import DateDefaults from "@/entities/date-defaults";

const state = {
  periodi: [],
  periodoSelezionato: {},
};

const getEstremiPeriodo = (s, fn) =>
  format(
    fn.call(
      null,
      new Date(s.periodoSelezionato.anno, s.periodoSelezionato.mese, 1)
    ),
    DateDefaults.formats.CLIENT
  );

const getters = {
  getPeriodi: (state) => state.periodi,
  getPeriodoSelezionato: (state) => state.periodoSelezionato,
  getFromPeriodo: (state) => getEstremiPeriodo(state, startOfMonth),
  getToPeriodo: (state) => getEstremiPeriodo(state, endOfMonth),
};

const actions = {
  async selezionaPeriodo({ commit }, value) {
    commit("setPeriodo", value);
  },
};

const mutations = {
  setPeriodiList(state, periodiList) {
    state.periodi = periodiList;
  },

  setNewRiga(state, value) {
    state.periodi = [...state.periodi, value];
  },

  setPeriodo(state, periodo) {
    state.periodoSelezionato = periodo;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

