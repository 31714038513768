/* eslint-disable  */
import StatusCodes from "http-status-codes";

import _ from "lodash";
import { parse, isAfter } from "date-fns";

import AppDefaults from "@/entities/app-defaults";
import LoginSteps from "@/entities/login-steps";
import DurataContratto from "@/entities/durata-contratto";
import DateDefaults from "@/entities/date-defaults";
import axiosErrors from "@/entities/axios-errors";

import { _l } from "@/entities/log";
import { helperLocalStorage } from "@/entities/storage-helper";
import { _copyObject } from "@/entities/funzioni-comuni";

// se è indeterminato fine === null
const contrattoValido = (contratto) => {
  const dataFine = parse(
    contratto.dataFine,
    DateDefaults.formats.CLIENT,
    new Date()
  );
  return (
    (contratto.durataContratto === DurataContratto.TEMPO_INDETERMINATO &&
      (contratto.dataFine === null || isAfter(dataFine, new Date()))) ||
    (contratto.durataContratto === DurataContratto.TEMPO_DETERMINATO &&
      isAfter(dataFine, new Date()))
  );
};

const baseState = {
  loginStep: LoginSteps.CREDENZIALI,
  errore: null,
  token: helperLocalStorage.TOKEN.get(),
  credenziali: {},
  contrattoAttivo: helperLocalStorage.CONTRATTO_ATTIVO.get(),
  contratti: [],
  firstTime: true, //HO APPENA FATTO IL LOGIN?
};

const authModule = {
  namespaced: true,
  state: () => _copyObject(baseState),
  mutations: {
    setFirstTime(state, firstTime) {
      state.firstTime = firstTime;
    },
    resetState(state) {
      state.loginStep = LoginSteps.CREDENZIALI;
      resetTokenAndContrattoAttivo(state);
      state.credenziali = {};
      state.contratti = [];
    },
    resetTokenAndContrattoAttivo(state) {
      resetTokenAndContrattoAttivo(state);
    },
    setContrattoAttivo(state, contrattoAttivo) {
      state.contrattoAttivo = contrattoAttivo;
      setValueContrattoAttivoInStorage(state.contrattoAttivo);
    },
    setContratti(state, contratti) {
      state.contratti = contratti;
    },
    setLoginStep(state, step) {
      state.loginStep = step;
    },
    initDatiLoginFromStorage(state, { token, contrattoAttivo }) {
      if (_.isNil(token)) {
        state.loginStep = LoginSteps.CREDENZIALI;
        state.token = null;
        state.contrattoAttivo = null;
      } else {
        state.loginStep = LoginSteps.LOGGED_IN;
        state.token = token;
        state.contrattoAttivo = contrattoAttivo;
      }
      setValueTokenInStorage(state.token);
      setValueContrattoAttivoInStorage(state.contrattoAttivo);
    },
    setDatiLogin(state, token) {
      if (_.isNil(token)) {
        state.loginStep = LoginSteps.CREDENZIALI;
        throw Error("Errore nell'impostare i dati di autenticazione");
      }
      state.token = token;
      setValueTokenInStorage(state.token);
    },
    setErrore(state, errore) {
      state.errore = errore;
    },
  },
  actions: {
    async login({ commit, dispatch, getters }, credenziali) {
      this._vm.toggleLoader(true);
      try {
        const loginRes = await this._vm.$api.auth.login(credenziali);
        if (loginRes.status == StatusCodes.OK) {
          /// memorizzo l'informazione del codice azienda nello storage
          helperLocalStorage.CODICE_AZIENDA.set(credenziali.azienda);

          const contratti = (await this._vm.$api.auth.listaContratti()).data;
          if (contratti.length) {
            commit("setContratti", contratti);
          }
          if (getters.contrattiValidi.length === 1) {
            // accedo con l'unico contratto
            return await dispatch(
              "selectContratto",
              getters.contrattiValidi[0]
            );
          } else {
            commit("setLoginStep", LoginSteps.SELEZIONE_CONTRATTO);
          }
        } else {
          // console.log(this._vm.$api);
          let message = AppDefaults.ERRORE_GENERICO;
          if (_.isNil(loginRes.response)) {
            message = axiosErrors[loginRes.code] || loginRes.message;
          } else {
            message = loginRes.response.data;
          }
          commit("setErrore", message);
        }
      } catch (error) {
        commit("setErrore", error.message);
      }
      this._vm.toggleLoader(false);
    },
    async selectContratto({ commit }, contratto) {
      this._vm.toggleLoader(true);

      try {
        const response = await this._vm.$api.auth.selezionaContratto(
          contratto.id
        );
        if (response.status == StatusCodes.OK) {
          commit("setContrattoAttivo", contratto.id);
          commit("setDatiLogin", response.data);
          commit("setLoginStep", LoginSteps.LOGGED_IN);
        } else {
          let message = AppDefaults.ERRORE_GENERICO;
          if (_.isNil(response.response)) {
            message = axiosErrors[response.code] || response.message;
          } else {
            message = response.response.data.split(":")[0];
          }
          commit(
            "setErrore",
            _l("store/auth:137 selectContratto:response != 200", message)
          );
        }
      } catch (error) {
        commit("setErrore", error.message);
      }
    },
    async refreshToken({ commit, getters }) {
      try {
        const response = await this._vm.$api.auth.selezionaContratto(
          getters.contrattoAttivo
        );
        if (response.status === StatusCodes.OK) {
          await commit("setDatiLogin", response.data);
        } else {
          _l("store/auth:166 refreshToken response != OK", response);
          commit("resetState");
        }
      } catch (error) {
        _l("store/auth:166 refreshToken", error);
      }
    },
    async logout({ commit }) {
      this._vm.toggleLoader(true);
      await commit("resetState");
      // console.log("resetState OK?");
      await this._vm.$api.auth.logout();
      // console.log("logout ok");
      this._vm.toggleLoader(false);
    },
    cambiaContratto({ commit }) {
      commit("setLoginStep", LoginSteps.SELEZIONE_CONTRATTO);
      commit("resetTokenAndContrattoAttivo");
      commit("setSelectedContract", null, { root: true });
    },
    setLoginStep({ commit }, step) {
      commit("setLoginStep", step);
    },

    async reset({ commit }, datiReset) {
      // console.log("reset", datiReset);
      try {
        const response = await this._vm.$api.auth.reset(datiReset);
        // console.log("response", response);
        if (response.status === StatusCodes.NO_CONTENT) {
          //await commit("setAccessToken", response.data);
          return true;
        } else {
          // _l("store/auth: refreshToken response != OK", response);
          // commit("resetState");
          return false;
        }
      } catch (error) {
        _l("store/auth: reset", error);
        return false;
      }
    },

    async impostaPassword({ commit }, { datiReset, recaptchaToken }) {
      // console.log("impostaPassword", datiReset);
      try {
        const response = await this._vm.$api.auth.settaPasswordUtente(
          datiReset,
          recaptchaToken
        );
        // console.log("response", response);
        if (response.status === StatusCodes.NO_CONTENT) {
          //await commit("setAccessToken", response.data);
          return true;
        } else {
          throw new Error(
            "Errore server durante la registrazione della password: " +
              response.response.data
          );

          // _l("store/auth: refreshToken response != OK", response);
          // commit("resetState");
          return false;
        }
      } catch (error) {
        _l("store/auth: reset", error);
        commit("setErrore", error.message);
        return false;
      }
    },

    async resetLoginStep({ commit }) {
      // console.log("resetLoginStep");
      await commit("resetState");
      await commit("setDatiApplicazione", null, { root: true });
      // La riga successiva serve per fare in modo che
      // non rimangano dati "sporchi" al login con un altro utente
      window.location.href = window.location.pathname;
      await this._vm.$api.auth.logout();
    },
    setContratti({ commit }, contratti) {
      commit("setContratti", contratti);
    },
    setContrattoAttivo({ commit }, contrattoAttivo) {
      commit("setContrattoAttivo", contrattoAttivo);
    },
    setErrore({ commit }, errore) {
      commit("setErrore", errore);
    },
    resetErrore({ commit }) {
      commit("setErrore", null);
    },
    async fetchContrattiUtente({ commit, getters }) {
      // Serve a non fare la doppia chiamata del login iniziale
      if (getters.firstTime && getters.contratti.length !== 0) {
        commit("setFirstTime", false);
        return;
      }
      try {
        const axiosResponse = await this._vm.$api.auth.listaContratti();
        if (isStatusSuccess(axiosResponse.status)) {
          commit("setContratti", axiosResponse.data.sort(compare));
        } else {
          throw new Error(axiosResponse.response.data);
        }
      } catch (error) {
        commit("setContratti", []);
        throw new Error(
          "Errore nella ricerca dei contratti dell'utente: " + error
        );
      }
    },
  },

  getters: {
    firstTime: (state) => state.firstTime,
    token: (state) => state.token,
    loginStep: (state) => state.loginStep,
    errore: (state) => state.errore,
    isUserLoggedIn: (state) => state.loginStep === LoginSteps.LOGGED_IN,
    contratti: (state) => state.contratti,
    hasSceltaContratto: (state) =>
      state.contratti.filter(contrattoValido).length > 1,
    contrattiValidi: (state) => state.contratti.filter(contrattoValido),
    contrattoAttivo: (state) => state.contrattoAttivo,
  },
};

function isStatusSuccess(statusCode) {
  return statusCode >= 200 && statusCode <= 299;
}

function resetTokenAndContrattoAttivo({ token, contrattoAttivo }) {
  token = null;
  setValueTokenInStorage(token);
  contrattoAttivo = null;
  setValueContrattoAttivoInStorage(contrattoAttivo);
}

function setValueTokenInStorage(value) {
  setValueObjectInStorage(helperLocalStorage.TOKEN, value);
}
function setValueContrattoAttivoInStorage(value) {
  setValueObjectInStorage(helperLocalStorage.CONTRATTO_ATTIVO, value);
}
function setValueObjectInStorage(object, value) {
  if (value == null) {
    object.remove();
  } else {
    object.set(value);
  }
}

/** Ordina per mansione i contratti */
function compare(a, b) {
  if (a.mansione < b.mansione) {
    return -1;
  }
  if (a.mansione > b.mansione) {
    return 1;
  }
  return 0;
}

export default authModule;

