// import LZString from "lz-string";

/// dovrebbe essere una classe privata
class _HelperStorage {
  constructor(storage, name, compressValue) {
    this.storage = storage;
    this.name = name;
    this.compressValue = compressValue;
  }
  set(value) {
    if (this.compressValue) {
      // value = LZString.compressToUTF16(JSON.stringify(value));
    }
    this.storage.setItem(this.name, value);
  }
  get() {
    // const t = this.compressValue
    //   ? LZString.decompressFromUTF16(this.storage.getItem(this.name))
    //   : this.storage.getItem(this.name);
    return this.storage.getItem(this.name);
  }
  remove() {
    this.storage.removeItem(this.name);
  }
}

// eslint-disable-next-line no-unused-vars
class HelperSessionStorage extends _HelperStorage {
  constructor(name, compressValue = false) {
    super(sessionStorage, name, compressValue);
  }
}
class HelperLocalStorage extends _HelperStorage {
  constructor(name, compressValue = false) {
    super(localStorage, name, compressValue);
  }
}

export const helperSessionStorage = Object.freeze({
  // PERIODO: new HelperSessionStorage("periodo"),
});

export const helperLocalStorage = Object.freeze({
  TYPE_VIEW_LOCALSTORAGE: new HelperLocalStorage("typeViewCalendar"),
  TOKEN: new HelperLocalStorage("t", true),
  CONTRATTO_ATTIVO: new HelperLocalStorage("idca", true),
  CODICE_AZIENDA: new HelperLocalStorage("codAz", false),
});
