import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import AppDefaults from "../entities/app-defaults";
import { _err } from "@/entities/log";
import { AuthLevelEnum } from "@/entities/auth-level.js";
import { getPeriodsForRouter } from "@/entities/type-view-calendar";

const Menu = Object.freeze({
  NONE: 0,
  DESKTOP: 1,
  MOBILE: 2,
});

Vue.use(VueRouter);

const routes = [
  {
    pathBase: "/",
    path: "/",
    redirect: "/calendario",
    meta: {
      menu: Menu.NONE,
      minimumLevelAuth: AuthLevelEnum.USER,
      onlyContrattoConTimbrature: false,
    },
  },
  {
    pathBase: "/login",
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginViewMida"),
    meta: {
      requiresAuth: false,
      menu: Menu.NONE,
      minimumLevelAuth: AuthLevelEnum.USER,
      onlyContrattoConTimbrature: false,
    },
  },
  {
    pathBase: "/reset",
    path: "/reset",
    name: "Reset",
    component: () =>
      import(
        /* webpackChunkName: "reset" */ "../views/ImpostaPasswordViewMida"
      ),
    meta: {
      requiresAuth: false,
      menu: Menu.NONE,
      minimumLevelAuth: AuthLevelEnum.USER,
      onlyContrattoConTimbrature: false,
    },
  },
  {
    pathBase: "/calendario",
    path: "/calendario/:view?/:dateFocus?",
    name: "Calendario",
    component: () =>
      import(/* webpackChunkName: "calendario" */ "../views/CalendarViewMida"),
    meta: {
      requiresAuth: true,
      icon: "mdi-calendar",
      menu: Menu.MOBILE,
      minimumLevelAuth: AuthLevelEnum.USER,
      onlyContrattoConTimbrature: false,
      validProps: { view: getPeriodsForRouter() },
    },
  },
  {
    pathBase: "/timbrature",
    path: "/timbrature/:periodo?/:idContratto?",
    name: "Timbrature",
    component: () =>
      import(
        /* webpackChunkName: "timbrature" */ "../views/TimbraturaViewMida"
      ),
    meta: {
      requiresAuth: true,
      icon: "mdi-clock-check",
      menu: Menu.MOBILE,
      minimumLevelAuth: AuthLevelEnum.USER,
      onlyContrattoConTimbrature: true,
    },
  },
  {
    pathBase: "/presenze",
    path: "/presenze/:periodo?/:idContratto?",
    name: "Presenze",
    component: () =>
      import(/* webpackChunkName: "presenze" */ "../views/PresenzeViewMida"),
    meta: {
      requiresAuth: true,
      icon: "mdi-check-network-outline",
      menu: Menu.MOBILE,
      minimumLevelAuth: AuthLevelEnum.USER,
      onlyContrattoConTimbrature: false,
    },
  },
  {
    pathBase: "/rimborsi",
    path: "/rimborsi/:periodo?/:idContratto?",
    name: "Rimborsi",
    component: () =>
      import(/* webpackChunkName: "rimborsi" */ "../views/RimborsiViewMida"),
    meta: {
      requiresAuth: true,
      icon: "mdi-account-cash",
      menu: Menu.MOBILE,
      minimumLevelAuth: AuthLevelEnum.USER,
      onlyContrattoConTimbrature: false,
    },
  },
  {
    pathBase: "/abilitazioni",
    path: "/abilitazioni/:idContratto?/:idAbilitazione?",
    name: "Abilitazioni",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "abilitazioni" */ "../views/AbilitazioniViewMida"
      ),
    meta: {
      requiresAuth: true,
      icon: "mdi-certificate", // "mdi-school",
      menu: Menu.MOBILE,
      minimumLevelAuth: AuthLevelEnum.USER,
      onlyContrattoConTimbrature: false,
    },
  },
  {
    pathBase: "/documenti",
    path: "/documenti/:periodo?/:idContratto?",
    name: "Documenti",
    component: () =>
      import(/* webpackChunkName: "documenti" */ "../views/DocumentiViewMida"),
    meta: {
      requiresAuth: true,
      icon: "mdi-file-document",
      menu: Menu.MOBILE,
      minimumLevelAuth: AuthLevelEnum.USER,
      onlyContrattoConTimbrature: false,
    },
  },
  {
    pathBase: "/contratto",
    path: "/contratto/:idContratto?/:idVariazioneContratto?",
    name: "Dati contratto",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "contratto" */ "../views/DatiContrattoViewMida"
      ),
    meta: {
      requiresAuth: true,
      icon: "mdi-file-sign",
      menu: Menu.MOBILE,
      minimumLevelAuth: AuthLevelEnum.USER,
      onlyContrattoConTimbrature: false,
    },
  },
  {
    pathBase: "/richieste",
    path: "/richieste/:idRichiesta?",
    name: "Richieste",
    props: true,
    component: () =>
      import(/* webpackChunkName: "richieste" */ "../views/RichiesteViewMida"),
    meta: {
      requiresAuth: true,
      icon: "mdi-help-circle-outline",
      menu: Menu.MOBILE,
      minimumLevelAuth: AuthLevelEnum.COMPANY_ADMIN,
      onlyContrattoConTimbrature: false,
    },
  },
  {
    pathBase: "/utenti",
    path: "/utenti/:idUtente?",
    name: "Utenti",
    props: true,
    component: () =>
      import(/* webpackChunkName: "utenti" */ "../views/UtentiViewMida"),
    meta: {
      requiresAuth: true,
      icon: "mdi-account-group",
      menu: Menu.MOBILE,
      minimumLevelAuth: AuthLevelEnum.COMPANY_ADMIN,
      onlyContrattoConTimbrature: false,
    },
  },

  {
    pathBase: "/elaborazione",
    path: "/elaborazione",
    name: "Elaborazione",
    props: true,
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/ElaborazioneViewMida"),
    meta: {
      requiresAuth: true,
      icon: "mdi-shield-crown",
      menu: Menu.MOBILE,
      minimumLevelAuth: AuthLevelEnum.COMPANY_ADMIN,
      onlyContrattoConTimbrature: false,
    },
  },
];

const menuRoutesUser = () =>
  routes.filter(
    ({ meta }) =>
      meta.minimumLevelAuth === AuthLevelEnum.USER &&
      (!meta.onlyContrattoConTimbrature ||
        (meta.onlyContrattoConTimbrature &&
          store.getters.contratto.conTimbratura))
  );
const menuRoutesCompanyAdmin = () =>
  routes.filter(
    ({ meta }) =>
      meta.minimumLevelAuth === AuthLevelEnum.USER ||
      meta.minimumLevelAuth === AuthLevelEnum.COMPANY_ADMIN
  );
const menuRoutesTaxAdvisor = () =>
  routes.filter(
    ({ meta }) =>
      meta.minimumLevelAuth === AuthLevelEnum.USER ||
      meta.minimumLevelAuth === AuthLevelEnum.COMPANY_ADMIN ||
      meta.minimumLevelAuth === AuthLevelEnum.TAX_ADVISOR
  );
const menuRoutesSystemAdmin = () => routes;

const router = new VueRouter({
  routes,

  menuRoutesByLevelAuth() {
    let routesFiltered = [];
    switch (store.getters.authLevel.authLevel) {
      case AuthLevelEnum.USER:
        routesFiltered = menuRoutesUser();
        break;
      case AuthLevelEnum.COMPANY_ADMIN:
        routesFiltered = menuRoutesCompanyAdmin();
        break;
      case AuthLevelEnum.TAX_ADVISOR:
        routesFiltered = menuRoutesTaxAdvisor();
        break;
      case AuthLevelEnum.SYSTEM_ADMIN:
        routesFiltered = menuRoutesSystemAdmin();
        break;
    }

    return store.getters.isMobile
      ? filterMobileRoutes(routesFiltered)
      : filterDesktopRoutes(routesFiltered);
  },
});

function filterDesktopRoutes(routes) {
  return routes.filter((r) => r.meta.menu !== Menu.NONE);
}
function filterMobileRoutes(routes) {
  return routes.filter((r) => r.meta.menu === Menu.MOBILE);
}

router.beforeEach(async (to, _, next) => {
  // redirect home su route non riconosciuta

  let isValidProps = true;
  if (to.meta.validProps && to.params) {
    for (let key in to.meta.validProps) {
      isValidProps = to.meta.validProps[key].includes(to.params[key]);
    }
  }
  if (to.matched.length === 0 || !isValidProps) {
    next(false);
    return;
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  let authorized = !requiresAuth;

  if (requiresAuth) {
    try {
      authorized = await store.dispatch("initFromStorage");
    } catch (error) {
      _err("ERRORE da initFromStorage", error);
    }
  }

  if (!authorized) {
    store.dispatch("setActiveSection", null);
    next("/login");
  } else {
    store.dispatch("setActiveSection", to.name);
    next();
    // In caso di utente già loggato (con un token attivo)
    // se dovessi aprire un nuovo tab con l'URL che contiene
    // "/login", mi fa un redirect
    if (to.fullPath === "/login" && !requiresAuth) {
      await store.dispatch("redirectCambiaContratto");
    }
  }
});

router.beforeResolve((to, from, next) => {
  let canProceed = true;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  // non serve controllare l'utente se non è loggato perché vengono fatti nel metodo "beforeEach"
  if (store.getters.utente && requiresAuth) {
    // ottengo le sezioni abilitate all'utente loggato
    canProceed = !!router.options
      .menuRoutesByLevelAuth()
      .find(({ name }) => name === to.name);
  }

  if (canProceed) {
    next();
  } else {
    // se non può entrare nella sezione forzo il redirect sul calendario!
    next("/");
  }
});

router.afterEach((to, from) => {
  // if (to.name === "Login") {
  //   clearStore(["abilitazioni", "rimborsi"]);
  // }
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    const prefix = to.name ? to.name + " - " : "";
    document.title = prefix + AppDefaults.NOME_APP;

    if (from.name !== null && from.name !== to.name) {
      document.dispatchEvent(new CustomEvent("checkUpdates"));
    }
  });
});

export default router;
