export default function () {
  return {
    ...this,

    paymentIntent(richiesta) {
      return this.call({
        needsAuth: true,
        method: this._method.GET,
        url: `/userApi/stripe/${richiesta.id}`,
      });
    },
  };
}
