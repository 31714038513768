import DateUtils from "@/entities/date-utils";
import { AlertType } from "@/entities/alert.js";
import { Attachment } from "@/entities/attachment";
import { idContrattoGenerico } from "@/entities/contractsList";
import { _copyObject } from "@/entities/funzioni-comuni";

const state = {
  documents: [],
  PDFBustePaga: null,
  waiting: false,
  isDialogVisible: false,
  documentSelected: null,
  defaultDocument: null,
};

const getters = {
  documents: ({ documents }) => documents,
  isDialogVisible: ({ isDialogVisible }) => isDialogVisible,
  documentSelected: ({ documentSelected }) => documentSelected,
  defaultDocument: ({ defaultDocument }) => defaultDocument,
  PDFBustePaga: (state) => state.PDFBustePaga,
  waiting: (state) => state.waiting,
  statusOperation: (state) => state.statusOperation,
};

const mutations = {
  setDocuments(state, documents) {
    state.documents = documents;
  },
  setPDFBustePaga(state, PDFBustePaga) {
    state.PDFBustePaga = PDFBustePaga;
  },
  setWaiting(state, waiting) {
    state.waiting = waiting;
  },
  setDialogVisible: (state, isDialogVisible) =>
    (state.isDialogVisible = isDialogVisible),
  setDocumentSelected: (state, documentSelected) =>
    (state.documentSelected = documentSelected),

  setDefaultDocument: (state, defaultDocument) =>
    (state.defaultDocument = defaultDocument),
};

const actions = {
  setWaiting({ commit }, waiting = true) {
    commit("setWaiting", waiting);
  },
  setDialogVisible({ commit }, isDialogVisible) {
    commit("setDialogVisible", isDialogVisible);
  },
  setDocumentSelected({ commit }, documentSelected) {
    commit("setDocumentSelected", documentSelected);
  },

  setDefaultDocument({ commit }, defaultDocument) {
    commit("setDefaultDocument", defaultDocument);
  },

  resetBustaPaga({ commit }) {
    commit("setPDFBustePaga", null);
  },

  async fetchDocuments({ commit, dispatch, rootGetters }) {
    try {
      const periodo = DateUtils.formatDateToNumberMonthYear(
        new Date(rootGetters.monthAndYearSelected)
      );

      const axiosResponse = await this._vm.$api.documento.fetchDocuments(
        periodo,
        rootGetters.selectedContract.id
      );
      if (isStatusSuccess(axiosResponse.status)) {
        await commit("setDocuments", addPropertiesToArray(axiosResponse.data));
      } else {
        await commit("setDocuments", []);
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      await commit("setDocuments", []);
      createAndSetErrorAlert(dispatch, error.message);
    }
  },

  async fetchDocument({ dispatch, rootGetters }, document) {
    try {
      const periodo = DateUtils.formatDateToNumberMonthYear(
        new Date(rootGetters.monthAndYearSelected)
      );

      const axiosResponse = await this._vm.$api.documento.fetchDocument(
        periodo,
        rootGetters.selectedContract.id,
        document.nomeFile
      );
      if (isStatusSuccess(axiosResponse.status)) {
        if (
          isMyDocument(
            document,
            rootGetters.authLevel,
            rootGetters.contratto
          ) &&
          !document.download
        ) {
          document.downloaded = "Ora..";
        }
        return await URL.createObjectURL(axiosResponse.data);
      } else {
        throw new Error("Download del documento fallito");
      }
    } catch ({ message }) {
      createAndSetErrorAlert(dispatch, message);
    }
  },

  async addDocument({ commit, dispatch, getters, rootGetters }) {
    try {
      const axiosResponse = await this._vm.$api.documento.addDocument(
        rootGetters.selectedContract.id,
        getters.documentSelected.periodo,
        getters.documentSelected.attachment.generateFormData({
          tipoDocumento: getters.documentSelected.docType,
        })
      );
      if (isStatusSuccess(axiosResponse.status)) {
        getters.documents.push(addPropertiesToDocument(axiosResponse.data));
        await commit("setDocuments", getters.documents);

        commit("setDefaultDocument", _copyObject(axiosResponse.data));
        createAndSetSuccessAlert(dispatch, true, "Documento aggiunto");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      createAndSetErrorAlert(dispatch, message);
    }
  },

  async deleteDocument({ commit, dispatch, getters, rootGetters }) {
    try {
      const axiosResponse = await this._vm.$api.documento.deleteDocument(
        rootGetters.selectedContract.id,
        getters.documentSelected.periodo,
        getters.documentSelected.attachment.name
      );
      if (isStatusSuccess(axiosResponse.status)) {
        const docs = removeDocumentFromArray(
          getters.documentSelected.attachment.name,
          getters.documents
        );
        await commit("setDocuments", docs);
        createAndSetSuccessAlert(dispatch, true, "Documento eliminato");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      createAndSetErrorAlert(dispatch, message);
    }
  },
};

function isMyDocument(document, authLevel, contractLogged) {
  if (authLevel.isAtLeastTaxAdvisor()) {
    return false;
  }
  if (document.idContratto == idContrattoGenerico) {
    // documenti azienda
    return authLevel.isCompanyAdmin();
  }

  if (contractLogged === null) {
    return false;
  }
  return document.idContratto === contractLogged.id;
}

function addPropertiesToArray(documents) {
  return documents.map(addPropertiesToDocument);
}
function addPropertiesToDocument(d) {
  return {
    ...d,
    attachment: Attachment.buildAttachmentByName(d.nomeFile),
  };
}

function removeDocumentFromArray(name, documents) {
  const index = documents.findIndex(({ nomeFile }) => nomeFile === name);
  if (index > -1) {
    documents.splice(index, 1);
  }
  return documents;
}

function isStatusSuccess(statusCode) {
  return statusCode >= 200 && statusCode <= 299;
}

function createAndSetErrorAlert(dispatch, msg, duration) {
  const alert = {
    show: true,
    type: AlertType.ERROR,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}
function createAndSetSuccessAlert(dispatch, show, msg, duration) {
  const alert = {
    show,
    type: AlertType.SUCCESS,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}
function setAlert(dispatch, alert) {
  dispatch("setAlert", alert, { root: true });
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

