export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      abilitazioni: "/userApi/abilitazione/",
    },

    // ABILITAZIONI

    fetchAbilitazioni(idContratto) {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.abilitazioni}${idContratto}`,
        needsAuth: true,
      });
    },

    addAbilitazione(idContratto, abilitazione) {
      return this.call({
        method: this._method.POST,
        url: `${this.uris.abilitazioni}${idContratto}`,
        needsAuth: true,
        data: abilitazione,
      });
    },

    editAbilitazione(idContratto, abilitazione) {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.abilitazioni}${idContratto}`,
        needsAuth: true,
        data: abilitazione,
      });
    },

    deleteAbilitazione(idContratto, abilitazione) {
      return this.call({
        method: this._method.DELETE,
        url: `${this.uris.abilitazioni}${idContratto}/${abilitazione.id}`,
        needsAuth: true,
      });
    },

    getStatsMese() {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.abilitazioni}stats`,
        needsAuth: true,
      });
    },

    // ATTESTATI ABILITAZIONE

    addAttestato(idContratto, idAbilitazione, attestato) {
      return this.call({
        method: this._method.POST,
        url: `${this.uris.abilitazioni}${idContratto}/${idAbilitazione}/attestato`,
        needsAuth: true,
        data: attestato,
      });
    },

    editAttestato(idContratto, idAbilitazione, attestato) {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.abilitazioni}${idContratto}/${idAbilitazione}/attestato`,
        needsAuth: true,
        data: attestato,
      });
    },

    deleteAttestato(idContratto, idAbilitazione, idAttestato) {
      return this.call({
        method: this._method.DELETE,
        url: `${this.uris.abilitazioni}${idContratto}/${idAbilitazione}/attestato/${idAttestato}`,
        needsAuth: true,
      });
    },

    // ALLEGATI ATTESTATI

    addAllegato(idContratto, idAbilitazione, idAttestato, attachment) {
      return this.call({
        header: {
          "Content-Type": "multipart/form-data",
        },
        method: this._method.POST,
        url: `${this.uris.abilitazioni}${idContratto}/${idAbilitazione}/attestato/${idAttestato}/attachment`,
        needsAuth: true,
        data: attachment,
      });
    },

    getAllegato(idContratto, idAbilitazione, idAttestato) {
      return this.call({
        method: this._method.GET,
        responseType: "blob",
        url: `${this.uris.abilitazioni}${idContratto}/${idAbilitazione}/attestato/${idAttestato}/attachment`,
        needsAuth: true,
      });
    },

    deleteAllegato(idContratto, idAbilitazione, idAttestato) {
      return this.call({
        method: this._method.DELETE,
        url: `${this.uris.abilitazioni}${idContratto}/${idAbilitazione}/attestato/${idAttestato}/attachment`,
        needsAuth: true,
      });
    },
  };
}
