import SubjectCommon from "@/entities/subject-common.js";
import { AlertType } from "@/entities/alert.js";
import StateEvents from "@/entities/state-event.js";
import DateDefaults from "@/entities/date-defaults";
import { getYear, isAfter, isBefore, format } from "date-fns";

const state = {
  isDialogVisible: false,
  eventSelected: {},
  isStatePendentOriginalValueEventSelected: false,
  dateFrom: null,
  dateTo: null,
  events: [],
  lastNewEventAdded: null,
};

const getters = {
  isDialogVisible: (state) => state.isDialogVisible,

  eventSelected: (state) => state.eventSelected,
  isStatePendentOriginalValueEventSelected: ({
    isStatePendentOriginalValueEventSelected,
  }) => isStatePendentOriginalValueEventSelected,

  dateFrom: ({ dateFrom }) => dateFrom,
  dateTo: ({ dateTo }) => dateTo,
  events: (state) => state.events,
  lastNewEventAdded: ({ lastNewEventAdded }) => lastNewEventAdded,

  contrattiGestibiliFilteredForCalendar:
    (state, getters, rootState, rootGetters) => (from, to) => {
      let contracts = rootGetters
        .contrattiGestibiliFilteredByDate(from, to)
        .map((c) => ({
          id: c.id,
          nomeSoggetto: c.nomeSoggetto,
          nameToShow: SubjectCommon.setNameToShowUser(
            c,
            rootGetters.contrattiGestibili
          ),
        }));

      contracts.push({ id: -1, nomeSoggetto: "TUTTI", nameToShow: "TUTTI" });
      return contracts;
    },
};

const actions = {
  showDialogEvent({ commit }, value) {
    commit("showDialogEvent", value);
  },
  deleteRecordArray({ commit }, idToDelete) {
    commit("deleteRecordArray", idToDelete);
  },
  setEventSelected({ commit }, event) {
    commit("setEventSelected", event);
  },
  setDateFrom({ commit }, date) {
    commit("setDateFrom", date);
  },
  setDateTo({ commit }, date) {
    commit("setDateTo", date);
  },

  async fetchEvents({ getters, dispatch, commit, rootGetters }) {
    try {
      const axiosResponse = await this._vm.$api.calendar.fetchEvents(
        getters.dateFrom,
        getters.dateTo
      );

      if (isStatusSuccess(axiosResponse.status)) {
        if (!axiosResponse.data.length) {
          // problema: se l'array è vuoto l'animazione non si attiva
          // BRUTTA soluzione: aggiungo un elemento vuoto che sarà ignorato dall'interfaccia..
          axiosResponse.data.push({});
        }
        const eventsModified = formatArrayEvents(
          axiosResponse.data,
          rootGetters.contrattiGestibili
        );
        addHolidays(
          eventsModified,
          getters.dateFrom,
          getters.dateTo,
          rootGetters.azienda
        );
        // console.log(eventsModified);
        commit("setEvents", eventsModified);
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      createAndSetErrorAlert(dispatch, message);
    }
  },

  async addEvent({ getters, dispatch, commit }) {
    try {
      const axiosResponse = await this._vm.$api.calendar.addEvent(
        getters.eventSelected
      );
      if (isStatusSuccess(axiosResponse.status)) {
        if (getters.eventSelected.ripetizione) {
          await dispatch("fetchEvents");
        } else {
          dispatch("deleteRecordArray", getters.eventSelected.id);
          getters.events.push({
            ...getters.eventSelected,
            id: axiosResponse.data,
          });
        }
        commit(
          "setLastNewEventAdded",
          this._vm.helpers.copyObject(getters.eventSelected)
        );
        createAndSetSuccessAlert(
          dispatch,
          false,
          "Evento aggiunto con successo"
        );
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      createAndSetErrorAlert(dispatch, message);
    }
  },

  async editEvent({ getters, dispatch }) {
    try {
      delete getters.eventSelected.idContratto;
      const axiosResponse = await this._vm.$api.calendar.editEvent(
        getters.eventSelected
      );
      if (isStatusSuccess(axiosResponse.status)) {
        if (getters.eventSelected.ripetizione) {
          await dispatch("fetchEvents");
        } else {
          dispatch("deleteRecordArray", getters.eventSelected.id);
          getters.events.push({
            ...getters.eventSelected,
          });
        }
        createAndSetSuccessAlert(
          dispatch,
          false,
          "Evento modificato con successo"
        );
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      createAndSetErrorAlert(dispatch, message);
    }
  },

  async acceptEventsPeriod({ dispatch }, { from, to }) {
    try {
      const axiosResponse = await this._vm.$api.calendar.acceptEventsPeriod(
        from,
        to
      );
      if (isStatusSuccess(axiosResponse.status)) {
        createAndSetSuccessAlert(
          dispatch,
          false,
          "Eventi approvati con successo"
        );
        await dispatch("fetchEvents");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      createAndSetErrorAlert(dispatch, message);
    }
  },

  async deleteEvent({ getters, dispatch }) {
    try {
      const axiosResponse = await this._vm.$api.calendar.deleteEvent(
        getters.eventSelected.id
      );
      if (isStatusSuccess(axiosResponse.status)) {
        if (getters.eventSelected.ripetizione) {
          await dispatch("fetchEvents");
        } else {
          dispatch("deleteRecordArray", getters.eventSelected.id);
        }
        createAndSetSuccessAlert(
          dispatch,
          false,
          "Evento eliminato con successo"
        );
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      createAndSetErrorAlert(dispatch, message);
    }
  },
};

const mutations = {
  showDialogEvent: (state, isDialogVisible) =>
    (state.isDialogVisible = isDialogVisible),
  deleteRecordArray: (state, idToDelete) => {
    const index = state.events.findIndex(({ id }) => id === idToDelete);
    if (index > -1) {
      state.events.splice(index, 1);
    }
  },
  setEventSelected: (state, event) => {
    state.eventSelected = event;
    if (event) {
      state.isStatePendentOriginalValueEventSelected =
        StateEvents.isStatePendente(event.stato);
    } else {
      state.isStatePendentOriginalValueEventSelected = false;
    }
  },
  setDateFrom: (state, date) => (state.dateFrom = date),
  setDateTo: (state, date) => (state.dateTo = date),
  setEvents: (state, events) => (state.events = events),
  setLastNewEventAdded: (state, lastNewEventAdded) =>
    (state.lastNewEventAdded = lastNewEventAdded),
};

function addHolidays(eventsModified, dateFrom, dateTo, azienda) {
  dateFrom = DateDefaults.parseWithFormat(
    dateFrom,
    DateDefaults.formats.CLIENT
  );
  dateTo = DateDefaults.parseWithFormat(dateTo, DateDefaults.formats.CLIENT);
  //Devo codificare la festa del patrono
  let festaPatrono;
  if (azienda.festaPatrono) {
    festaPatrono = format(
      DateDefaults.parseWithFormat(
        azienda.festaPatrono,
        DateDefaults.formats.CLIENT
      ),
      "MM-dd"
    );
  }
  let holidays = [
    { dataFesta: "01-01", annotazione: "Capodanno" },
    { dataFesta: "01-06", annotazione: "Epifania" },
    { dataFesta: "04-25", annotazione: "Liberazione" },
    { dataFesta: "05-01", annotazione: "Festa del lavoro" },
    { dataFesta: "06-02", annotazione: "Festa della Repubblica" },
    { dataFesta: "08-15", annotazione: "Ferragosto" },
    { dataFesta: "11-01", annotazione: "Tutti i Santi" },
    { dataFesta: "12-08", annotazione: "Immacolata" },
    { dataFesta: "12-25", annotazione: "Natale" },
    { dataFesta: "12-26", annotazione: "Santo Stefano" },
    { dataFesta: "12-31", annotazione: "San Silvestro" },
  ];

  if (festaPatrono) {
    holidays.push({ dataFesta: festaPatrono, annotazione: "Patrono" });
  }

  let arr = eventsModified;
  let dateHoliday;
  let stringHoliday;

  // Inserisco le feste
  holidays.forEach((holiday) => {
    stringHoliday = getYear(dateFrom) + "-" + holiday.dataFesta;
    dateHoliday = DateDefaults.parseWithFormat(
      stringHoliday,
      DateDefaults.formats.CLIENT
    );
    if (isAfter(dateHoliday, dateFrom) && isBefore(dateHoliday, dateTo)) {
      arr.push(createEventHoliday(dateHoliday, holiday.annotazione));
    }

    stringHoliday = getYear(dateTo) + "-" + holiday.dataFesta;
    dateHoliday = DateDefaults.parseWithFormat(
      stringHoliday,
      DateDefaults.formats.CLIENT
    );
    // Controllo che l'anno non sia lo stesso altrimenti si ripeterebbe
    if (arr.filter((e) => e.id === `${dateHoliday}`).length === 0) {
      if (isAfter(dateHoliday, dateFrom) && isBefore(dateHoliday, dateTo)) {
        arr.push(createEventHoliday(dateHoliday, holiday.annotazione));
      }
    }
  });
  eventsModified = addEaster(eventsModified);
  return eventsModified;
}

function addEaster(eventsModified) {
  // Pasqua fino dal 2020 al 2050
  const holidays = [
    { dataFesta: "2020-04-13" },
    { dataFesta: "2021-04-05" },
    { dataFesta: "2022-04-18" },
    { dataFesta: "2023-04-10" },
    { dataFesta: "2024-04-01" },
    { dataFesta: "2025-04-21" },
    { dataFesta: "2026-04-06" },
    { dataFesta: "2027-03-29" },
    { dataFesta: "2028-04-17" },
    { dataFesta: "2029-04-02" },

    { dataFesta: "2030-04-22" },
    { dataFesta: "2031-04-14" },
    { dataFesta: "2032-03-29" },
    { dataFesta: "2033-04-18" },
    { dataFesta: "2034-04-10" },
    { dataFesta: "2035-03-26" },
    { dataFesta: "2036-04-14" },
    { dataFesta: "2037-04-06" },
    { dataFesta: "2038-04-26" },
    { dataFesta: "2039-04-11" },

    { dataFesta: "2040-04-02" },
    { dataFesta: "2041-04-22" },
    { dataFesta: "2042-04-07" },
    { dataFesta: "2043-03-30" },
    { dataFesta: "2044-04-18" },
    { dataFesta: "2045-04-10" },
    { dataFesta: "2046-03-26" },
    { dataFesta: "2047-04-15" },
    { dataFesta: "2048-04-06" },
    { dataFesta: "2049-04-19" },
    { dataFesta: "2050-04-11" },
  ];

  let arr = eventsModified;
  let dateHoliday;
  let stringHoliday;

  // Inserisco le feste
  holidays.forEach((holiday) => {
    stringHoliday = holiday.dataFesta;
    dateHoliday = DateDefaults.parseWithFormat(
      stringHoliday,
      DateDefaults.formats.CLIENT
    );

    // Controllo che l'anno non sia lo stesso altrimenti si ripeterebbe
    if (arr.filter((e) => e.id === `${dateHoliday}`).length === 0) {
      arr.push(createEventHoliday(dateHoliday, "Lunedì di Pasqua"));
    }
  });

  return eventsModified;
}

function createEventHoliday(dateHoliday, annotazione) {
  return {
    id: `${dateHoliday}`,
    contratto: annotazione,
    codiceTipoEvento: {
      codice: "FES",
      descrizione: "FESTIVITA",
      tipoPrivacy: "PRESENTE",
    },
    from: dateHoliday,
    to: dateHoliday,
    stato: StateEvents.state.approvato,
    allDay: true,
  };
}

function formatArrayEvents(events, contrattiGestibili) {
  return events.map((e) => {
    if (e) {
      return {
        ...e,
        from: new Date(e.from),
        to: new Date(e.to),
        contratto: findContrattoByID(e.idContratto, contrattiGestibili),
      };
    }
  });
}
function findContrattoByID(idContratto, contrattiGestibili) {
  const c = contrattiGestibili.find(({ id }) => id === idContratto);
  if (!c && idContratto) {
    // il contratto di un soggetto che non è più presente tra i contratti gestibili del periodo
    return {
      id: -1,
      nomeSoggetto: "CONTRATTO ASSENTE",
    };
  }
  return c;
}

function isStatusSuccess(statusCode) {
  return statusCode >= 200 && statusCode <= 299;
}

function createAndSetErrorAlert(dispatch, msg, duration) {
  const alert = {
    show: true,
    type: AlertType.ERROR,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}
function createAndSetSuccessAlert(dispatch, show, msg, duration) {
  const alert = {
    show,
    type: AlertType.SUCCESS,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}
function setAlert(dispatch, alert) {
  dispatch("setAlert", alert, { root: true });
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

