export default function () {
  return {
    ...this,
    uris: {
      ...this.uris,
      aziende: "/adminApi/azienda/",
      elaborazioni: "/userApi/elaborazione/",
    },

    fetchAzienda(idAzienda) {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.aziende}${idAzienda}`,
        needsAuth: true,
      });
    },

    editAzienda(azienda) {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.aziende}`,
        needsAuth: true,
        data: azienda,
      });
    },

    fetchStatoElaborazioneAzienda() {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.elaborazioni}`,
        needsAuth: true,
      });
    },

    fetchLogElaborazioneAzienda() {
      return this.call({
        method: this._method.GET,
        url: `${this.uris.elaborazioni}logs`,
        needsAuth: true,
      });
    },

    avanti() {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.elaborazioni}avanti`,
        params: true,
        needsAuth: true,
      });
    },

    indietro() {
      return this.call({
        method: this._method.PUT,
        url: `${this.uris.elaborazioni}indietro`,
        params: false,
        needsAuth: true,
      });
    },
  };
}
