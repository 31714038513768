import _ from "lodash";
import { AlertType } from "@/entities/alert.js";
import { endOfMonth } from "date-fns";
import DateUtils from "@/entities/date-utils.js";
import StateEvents from "@/entities/state-event.js";

const STATUS_CODE_CONFLICT = 409;
const TEXT_ERROR_SIGNATURE = "Timbratura errata";

const state = {
  contractSelected: null,
  isDialogVisible: false,
  conflictMessageAddingSignature: null,
  signatureSelected: null,
  signatures: [],
};

const getters = {
  monthSelected: (state, getters, rootState) => {
    return new Date(rootState.monthAndYearSelected);
  },
  contractSelected: ({ contractSelected }) => contractSelected,
  isDialogVisible: ({ isDialogVisible }) => isDialogVisible,
  conflictMessageAddingSignature: ({ conflictMessageAddingSignature }) =>
    conflictMessageAddingSignature,
  signatureSelected: ({ signatureSelected }) => signatureSelected,
  signatures: ({ signatures }) => signatures,
};

const actions = {
  setContractSelected({ commit }, contractSelected) {
    commit("setContractSelected", contractSelected);
  },
  setDialogVisible({ commit }, isDialogVisible) {
    commit("setDialogVisible", isDialogVisible);
  },
  setConflictMessageAddingSignature(
    { commit },
    conflictMessageAddingSignature
  ) {
    commit("setConflictMessageAddingSignature", conflictMessageAddingSignature);
  },
  setSignatureSelected({ commit }, signature) {
    commit("setSignatureSelected", signature);
  },

  async fetchSignaturesMonthSelected({ commit, getters, dispatch }) {
    await fetchSignatures(
      this._vm,
      commit,
      dispatch,
      getters.contractSelected.id,
      extractDateFromByMonthSelected(getters.monthSelected),
      extractDateToByMonthSelected(getters.monthSelected)
    );
  },
  async fetchSignaturesByPeriod(
    { commit, dispatch },
    { idContratto, from, to }
  ) {
    await fetchSignatures(this._vm, commit, dispatch, idContratto, from, to);
  },

  async addSignature(
    { commit, dispatch, getters, rootGetters },
    forceSignature
  ) {
    try {
      const axiosResponse = await this._vm.$api.signing.addSignature(
        getters.contractSelected.id,
        getters.signatureSelected,
        forceSignature
      );

      if (isStatusSuccess(axiosResponse.status)) {
        if (rootGetters.isTimbraturaActiveSection) {
          if (
            isMonthSelectedEqualToMonthSignature(
              getters.monthSelected,
              axiosResponse.data.quando
            )
          ) {
            await addSignatureToArrayAndCheckConsistency(
              commit,
              axiosResponse.data,
              getters.signatures
            );
          }
        }
        const showAlert = _.isNil(getters.signatureSelected.quando);
        createAndSetSuccessAlert(dispatch, showAlert, "Timbratura aggiunta");
      } else if (axiosResponse.response.status === STATUS_CODE_CONFLICT) {
        dispatch(
          "setConflictMessageAddingSignature",
          axiosResponse.response.data
        );
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      createAndSetErrorAlert(dispatch, message);
    }
  },

  async editSignature({ commit, dispatch, getters }) {
    try {
      const axiosResponse = await this._vm.$api.signing.editSignature(
        getters.contractSelected.id,
        getters.signatureSelected
      );
      if (isStatusSuccess(axiosResponse.status)) {
        if (
          isMonthSelectedEqualToMonthSignature(
            getters.monthSelected,
            getters.signatureSelected.quando
          )
        ) {
          await replaceSignatureInArray(
            commit,
            getters.signatureSelected,
            getters.signatures
          );
        } else {
          await deleteSignature(
            commit,
            getters.signatureSelected.id,
            getters.signatures
          );
        }
        createAndSetSuccessAlert(dispatch, false, "Signatura modificata");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      createAndSetErrorAlert(dispatch, message);
    }
  },

  async acceptSignaturesMonth({ getters, commit, dispatch }) {
    try {
      const axiosResponse = await this._vm.$api.signing.acceptSignaturesMonth(
        getters.contractSelected.id,
        extractDateFromByMonthSelected(getters.monthSelected),
        extractDateToByMonthSelected(getters.monthSelected)
      );
      if (isStatusSuccess(axiosResponse.status)) {
        await acceptSignatures(commit, getters.signatures);
        createAndSetSuccessAlert(
          dispatch,
          false,
          "Timbrature approvate con successo"
        );
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      createAndSetErrorAlert(dispatch, message);
    }
  },

  async deleteSignature({ commit, dispatch, getters }) {
    try {
      const axiosResponse = await this._vm.$api.signing.deleteSignature(
        getters.contractSelected.id,
        getters.signatureSelected.id
      );
      if (isStatusSuccess(axiosResponse.status)) {
        await deleteSignature(
          commit,
          getters.signatureSelected.id,
          getters.signatures
        );
        createAndSetSuccessAlert(dispatch, false, "Timbratura eliminata");
      } else {
        createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      }
    } catch ({ message }) {
      createAndSetErrorAlert(dispatch, message);
    }
  },
};

const mutations = {
  setContractSelected: (state, contractSelected) =>
    (state.contractSelected = contractSelected),
  setDialogVisible: (state, isDialogVisible) =>
    (state.isDialogVisible = isDialogVisible),
  setConflictMessageAddingSignature: (state, conflictMessageAddingSignature) =>
    (state.conflictMessageAddingSignature = conflictMessageAddingSignature),
  setSignatureSelected: (state, signature) =>
    (state.signatureSelected = signature),
  setSignatures: (state, signatures) => (state.signatures = signatures),
};

async function fetchSignatures(_vm, commit, dispatch, idContratto, from, to) {
  try {
    const axiosResponse = await _vm.$api.signing.fetchSignatures(
      idContratto,
      from,
      to
    );

    if (isStatusSuccess(axiosResponse.status)) {
      const signaturesConverted = addPropertyAndConvertDataToArraySignature(
        axiosResponse.data
      );
      const signaturesChecked =
        setErrorToSignaturesIfErrata(signaturesConverted);
      commit("setSignatures", signaturesChecked);
    } else {
      createAndSetErrorAlert(dispatch, axiosResponse.response.data);
      commit("setSignatures", []);
    }
  } catch ({ message }) {
    createAndSetErrorAlert(dispatch, message);
    commit("setSignatures", []);
  }
}

function isStatusSuccess(statusCode) {
  return statusCode >= 200 && statusCode <= 299;
}

function extractDateFromByMonthSelected(month) {
  return DateUtils.formatDateToTypeClient(month);
}
function extractDateToByMonthSelected(month) {
  return DateUtils.formatDateToTypeClient(endOfMonth(month, 1));
}

function addSignatureToArrayAndCheckConsistency(commit, signature, signatures) {
  const signatureModified = addPropertyAndConvertDataToSignature(signature);
  signatures.push(signatureModified);
  sortAndCheckAndSetSignatures(commit, signatures);
}
function addPropertyAndConvertDataToArraySignature(signatures) {
  return signatures.map(addPropertyAndConvertDataToSignature);
}
function addPropertyAndConvertDataToSignature(signature) {
  return {
    ...signature,
    quando: new Date(signature.quando),
    error: "",
  };
}

function replaceSignatureInArray(commit, signature, signatures) {
  const signaturesModified = removeElementFromSignatures(
    signature.id,
    signatures
  );
  signaturesModified.push(signature);
  sortAndCheckAndSetSignatures(commit, signaturesModified);
}
function deleteSignature(commit, idSignature, signatures) {
  const signaturesModified = removeElementFromSignatures(
    idSignature,
    signatures
  );
  sortAndCheckAndSetSignatures(commit, signaturesModified);
}
function removeElementFromSignatures(idSignature, signatures) {
  const index = signatures.findIndex(({ id }) => id === idSignature);
  if (index > -1) {
    signatures.splice(index, 1);
  }
  return signatures;
}

function isMonthSelectedEqualToMonthSignature(monthSelected, dateSignature) {
  return (
    DateUtils.formatDateToNumberMonthYear(monthSelected) ===
    DateUtils.formatDateToNumberMonthYear(new Date(dateSignature))
  );
}

function sortAndCheckAndSetSignatures(commit, signatures) {
  const signaturesSorted = sortSignaturesByDate(signatures);
  const signaturesChecked = checkConsistencySignatures(signaturesSorted);

  commit("setSignatures", signaturesChecked);
}

function acceptSignatures(commit, signatures) {
  signatures.forEach(
    (signature) => (signature.stato = StateEvents.state.approvato.name)
  );
  commit("setSignatures", signatures);
}

function sortSignaturesByDate(signatures) {
  return signatures.sort((first, second) => {
    return first.quando - second.quando;
  });
}
function setErrorToSignaturesIfErrata(signatures) {
  signatures.forEach((signature) => {
    if (signature.errata) {
      signature.error = TEXT_ERROR_SIGNATURE;
    }
  });
  return signatures;
}
function checkConsistencySignatures(signatures) {
  let isPreviousUscita = true;

  signatures.forEach((signature) => {
    signature.errata = false;
    signature.error = "";
    if (StateEvents.isStateApprovatoOrPendente(signature.stato)) {
      if (signature.uscita === isPreviousUscita) {
        signature.errata = true;
        signature.error = TEXT_ERROR_SIGNATURE;
      }
      isPreviousUscita = signature.uscita;
    }
  });

  return checkIfPresentLastElementUscita(signatures);
}
function checkIfPresentLastElementUscita(signatures) {
  for (let i = signatures.length - 1; i > 0; i--) {
    if (StateEvents.isStateApprovatoOrPendente(signatures[i].stato)) {
      if (!signatures[i].uscita) {
        signatures[i].errata = true;
        signatures[i].error = TEXT_ERROR_SIGNATURE;
      }
    }
    break;
  }
  return signatures;
}

function createAndSetErrorAlert(dispatch, msg, duration) {
  const alert = {
    show: true,
    type: AlertType.ERROR,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}
function createAndSetSuccessAlert(dispatch, show, msg, duration) {
  const alert = {
    show,
    type: AlertType.SUCCESS,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}
function setAlert(dispatch, alert) {
  dispatch("setAlert", alert, { root: true });
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
