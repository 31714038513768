import Vue from "vue";
import base from "./api-base";
import auth from "./auth";
import calendar from "./calendar";
import signing from "./signing";
import rimborsi from "./rimborsi";
import presenze from "./presenze";
import abilitazioni from "./abilitazioni";
import documento from "./documento";
import datiContratto from "./datiContratto";
import richieste from "./richieste";
import stripe from "./stripe";
import utenti from "./utenti";
import admin from "./admin";

// api definito come plugin di vue
// e come observable per poterle
// mettere sotto watch

export default {
  install(v) {
    v.prototype.$api = Vue.observable({
      ...base,
      errore: null,
      calendar: calendar.apply(base, []),
      signing: signing.apply(base, []),
      auth: auth.apply(base, []),
      rimborsi: rimborsi.apply(base, []),
      presenze: presenze.apply(base, []),
      abilitazioni: abilitazioni.apply(base, []),
      documento: documento.apply(base, []),
      datiContratto: datiContratto.apply(base, []),
      richieste: richieste.apply(base, []),
      stripe: stripe.apply(base, []),
      utenti: utenti.apply(base, []),
      admin: admin.apply(base, []),
    });
  },
};
