import _ from "lodash";
import { isBefore, isEqual, differenceInDays, format } from "date-fns";
import DateDefaults from "@/entities/date-defaults";
import StatoAbilitazione from "@/entities/stato-abilitazione";
import { AlertType } from "@/entities/alert.js";
import { Attachment } from "@/entities/attachment";
import { _copyObject } from "@/entities/funzioni-comuni";

const getStato = (ab) => {
  let stato = StatoAbilitazione.PENDENTE;
  if (!_.isNil(ab.scadenza)) {
    if (!ab.terminata) {
      if (
        isBefore(parseIfString(ab.scadenza), new Date()) ||
        isEqual(parseIfString(ab.scadenza), new Date())
      ) {
        stato = StatoAbilitazione.SCADUTA;
      } else if (
        differenceInDays(parseIfString(ab.scadenza), new Date()) < 15
      ) {
        stato = StatoAbilitazione.IN_SCADENZA;
      } else if (ab.scadenza === "N/A" || ab.scadenza === null) {
        StatoAbilitazione.PENDENTE;
      } else {
        stato = StatoAbilitazione.VALIDA;
      }
    } else {
      stato = StatoAbilitazione.TERMINATA;
    }
  } else {
    if (ab.terminata) {
      stato = StatoAbilitazione.TERMINATA;
    } else {
      StatoAbilitazione.PENDENTE;
    }
  }
  return stato;
};
const getScadenza = (ab) => {
  const date = ab.attestati.map((at) => parseIfString(at.nuovaScadenza));
  const maxScadenza = new Date(Math.max(...date));
  return maxScadenza == "Invalid Date" ? "N/A" : maxScadenza;
};

const parseIfString = (d) =>
  typeof d === "string" ? DateDefaults.parseAnnoMeseGiorno(d) : d;

const state = {
  abilitazioni: [],
  abilitazioniConteggio: [],
  abilitazioneTemp: null,
  abilitazioneDefault: null,
  attestatoTemp: null,
  statusOperation: null,
  attachment: null,
  fileTemp: null,
  isPrimoDisponibile: true,
};

function addPropertiesToArray(list) {
  const abilitazioniModificate = list.map((abilitazione) => {
    const newAbilitazione = {
      ...abilitazione,
      attestati: abilitazione.attestati.map((attestato) => {
        const newAttestato = {
          ...attestato,
          attachment:
            attestato.nomeAllegato != null
              ? Attachment.buildAttachmentByName(attestato.nomeAllegato)
              : null,
        };
        return newAttestato;
      }),
    };
    return newAbilitazione;
  });

  return abilitazioniModificate;
}

const getters = {
  // recupera elemento tipi abilitazione in base al codice
  getTipoAbilitazione:
    (_state, _getters, _rootstate, rootgetters) => (codice) =>
      rootgetters.tipi.abilitazione.find((t) => t.codice === codice),
  getAbilitazioni: (state) => state.abilitazioni,
  getAbilitazioniConteggio: (state) => state.abilitazioniConteggio,
  // popola le abilitazioni con i dati necessari alla logica di visualizzazione
  getAbilitazioniMod: (_state, getters) => {
    const tipo = getters.getTipoAbilitazione;
    return getters.getAbilitazioni.map((ab) => ({
      ...ab,
      scadenza: getScadenza(ab),
      stato: getStato(ab),
      isOnlinePrimo: tipo(ab.codiceAbilitazione).onlinePrimo,
      isOnlineAggiornamento: tipo(ab.codiceAbilitazione).onlineAggiornamento,
      validita: tipo(ab.codiceAbilitazione).validita,
      attestati: ab.attestati.map((at) => ({
        ...at,
        quando: parseIfString(at.quando),
        nuovaScadenza: parseIfString(at.nuovaScadenza),
        attachment:
          at.nomeAllegato == null
            ? null
            : Attachment.buildAttachmentByName(at.nomeAllegato),
      })),
    }));
  },
  // filtra le abilitazioni in base al contratto selezionato
  getAbilitazioniContrattoScelto: (_state, getters, _, rootGetters) => {
    return getters.getAbilitazioniMod.filter(
      (a) => a.idContratto === rootGetters.selectedContract.id
    );
  },

  getTipiAbilitazioneConfronto: (_state, getters, rootstate, rootgetters) => {
    const codiciUtilizzati = getters.getAbilitazioni
      .filter(
        (a) =>
          a.idContratto === rootstate.selectedContract.id &&
          a.terminata === false
      )
      .map((a) => a.codiceAbilitazione);

    return rootgetters.tipi.abilitazione.filter(
      (t) => !codiciUtilizzati.includes(t.codice)
    );
    // return rootgetters.tipi.abilitazione;
  },

  // filtra i tipi abilitazione escludendo quelli già assegnati al contratto selezionato
  getTipiValidi: (_state, getters, rootstate, rootgetters) => {
    // const codiciUtilizzati = getters.getAbilitazioni
    //   .filter(
    //     (a) =>
    //       a.idContratto === rootstate.selectedContract.id &&
    //       a.terminata === false
    //   )
    //   .map((a) => a.codiceAbilitazione);

    // return rootgetters.tipi.abilitazione.filter(
    //   (t) => !codiciUtilizzati.includes(t.codice)
    // );
    return rootgetters.tipi.abilitazione;
  },
  statusOperation: (state) => state.statusOperation,
  getAttestati: (state) => state.abilitazioneTemp.attestati,
  //Tiene il file che poi verrà convertito in URL
  getAllegato: (state) => state.attachment,
  getIsPrimoDisponibile: (state) => state.isPrimoDisponibile,
  getAbilitazioneTemp: (state) => state.abilitazioneTemp,
  getAbilitazioneDefault: (state) => state.abilitazioneDefault,
  getAttestatoTemp: (state) => state.attestatoTemp,
  //Tiene il file in caso di inserimento riga nuova
  getFileTemp: (state) => state.fileTemp,
};

const actions = {
  async contaAbilitazioni({ rootGetters, commit, dispatch }) {
    const axiosResponse = await this._vm.$api.abilitazioni.getStatsMese();

    if (isStatusSuccess(axiosResponse.status)) {
      // Mi arriva un hashmap <idContratto, (numeroAbilitazioniScadute,numeroAbilitazioniInScadenza)>
      // Devo collegare l'id al contratto
      const contrattiConAbilitazioni =
        rootGetters.contrattiVisualizzabiliDaUtente
          .map((contratto) => {
            const idContratto = contratto.id;
            // Se l'id del contratto non è presente, impostiamo il numero di abilitazioni a 0
            const numeroAbilitazioni = axiosResponse.data[idContratto] || null;

            return {
              contratto: contratto,
              inScadenza: numeroAbilitazioni
                ? numeroAbilitazioni.inScadenza
                : null,
              scadute: numeroAbilitazioni ? numeroAbilitazioni.scadute : null,
            };
          })
          .filter((item) => item.scadute !== null || item.inScadenza !== null);
      commit("setAbilitazioniConteggio", contrattiConAbilitazioni);
    } else {
      creaAlertErrore(dispatch, axiosResponse.response.data);
      commit("setAbilitazioniConteggio", []);
    }
  },

  async aggiungiRiga({ commit, dispatch }, { idContratto, abilitazione }) {
    try {
      const axiosResponse = await this._vm.$api.abilitazioni.addAbilitazione(
        idContratto,
        abilitazione
      );

      if (isStatusSuccess(axiosResponse.status)) {
        abilitazione = axiosResponse.data;
        //Lo aggiungo alla lista
        commit("addAbilitazioneToList", abilitazione);
        commit("setAbilitazioneDefault", _copyObject(abilitazione));
        createAndSetSuccessAlert(
          dispatch,
          true,
          "Abilitazione aggiunta con successo"
        );
      } else {
        creaAlertErrore(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      creaAlertErrore(dispatch, error);
    }
  },

  async cancellaRiga({ commit, dispatch }, { idContratto, abilitazione }) {
    try {
      const axiosResponse = await this._vm.$api.abilitazioni.deleteAbilitazione(
        idContratto,
        abilitazione
      );

      if (isStatusSuccess(axiosResponse.status)) {
        //Lo cancello dalla lista
        commit("delAbilitazioneInList", abilitazione);
        createAndSetSuccessAlert(
          dispatch,
          true,
          "Abilitazione eliminata con successo"
        );
      } else {
        creaAlertErrore(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      creaAlertErrore(dispatch, error);
    }
  },

  async modificaRiga(
    { commit, dispatch, getters },
    { idContratto, abilitazione }
  ) {
    let abilitazioneModificata = _copyObject(abilitazione);

    // Vanno tolte le prop extra per passarle al backend
    delete abilitazioneModificata.stato;
    delete abilitazioneModificata.isOnlinePrimo;
    delete abilitazioneModificata.isOnlineAggiornamento;
    delete abilitazioneModificata.validita;
    abilitazioneModificata.attestati.map((at) => {
      delete at.id;
      delete at.attachment;
      delete at.isModificabile;
      delete at.allegatoPresente;
    });

    abilitazioneModificata.scadenza =
      abilitazioneModificata.scadenza === "N/A"
        ? null
        : abilitazioneModificata.scadenza;

    try {
      const axiosResponse = await this._vm.$api.abilitazioni.editAbilitazione(
        idContratto,
        abilitazioneModificata
      );

      if (isStatusSuccess(axiosResponse.status)) {
        abilitazione = axiosResponse.data;
        //Devo aggiornare lo stato e la scadenza dell'abilitazione
        abilitazione.scadenza = getScadenza(abilitazione);
        abilitazione.stato = getStato(abilitazione);
        //Lo aggiungo alla lista
        commit("modAbilitazioneInList", abilitazione);

        commit(
          "setAbilitazioneTemp",
          getters.getAbilitazioniMod.filter((ab) => abilitazione.id == ab.id)[0]
        );
        createAndSetSuccessAlert(
          dispatch,
          true,
          "Abilitazione modificata con successo"
        );
      } else {
        creaAlertErrore(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      creaAlertErrore(dispatch, error);
    }
  },

  async aggiungiRigaAttestato(
    { commit, dispatch, getters },
    { idContratto, idAbilitazione, attestato, attachment }
  ) {
    try {
      const axiosResponse = await this._vm.$api.abilitazioni.addAttestato(
        idContratto,
        idAbilitazione,
        attestato
      );
      let idAttestato = attestato.id;
      if (isStatusSuccess(axiosResponse.status)) {
        var abilitazioneModificata = axiosResponse.data;
        if (attachment != null) {
          var bodyFormData = new FormData();
          bodyFormData.append("file", attachment.file);
          idAttestato =
            abilitazioneModificata.attestati[
              abilitazioneModificata.attestati.length - 1
            ].id;
          await this._vm.$api.abilitazioni.addAllegato(
            idContratto,
            abilitazioneModificata.id,
            idAttestato,
            bodyFormData
          );

          // Sono riuscito a caricare?
          abilitazioneModificata.attestati =
            abilitazioneModificata.attestati.map(function changeAttestato(at) {
              // console.log("aggiungi riga", at);
              if (at.id == idAttestato) {
                return {
                  ...at,
                  attachment:
                    attestato.nomeAllegato == null
                      ? null
                      : Attachment.buildAttachmentByName(
                          attestato.nomeAllegato
                        ),
                };
              } else {
                return {
                  ...at,
                  attachment:
                    at.nomeAllegato == null
                      ? null
                      : Attachment.buildAttachmentByName(at.nomeAllegato),
                };
              }
            });
        }

        const tipo = getters.getTipoAbilitazione;

        abilitazioneModificata = {
          ...abilitazioneModificata,
          scadenza: getScadenza(abilitazioneModificata),
          stato: getStato(abilitazioneModificata),
          isOnlinePrimo: tipo(abilitazioneModificata.codiceAbilitazione)
            .onlinePrimo,
          isOnlineAggiornamento: tipo(abilitazioneModificata.codiceAbilitazione)
            .onlineAggiornamento,
          validita: tipo(abilitazioneModificata.codiceAbilitazione).validita,
          attestati: abilitazioneModificata.attestati.map((at) => ({
            ...at,
            quando: parseIfString(at.quando),
            nuovaScadenza: parseIfString(at.nuovaScadenza),
          })),
        };

        commit("addAttestatoToLista", abilitazioneModificata, attachment);

        if (attachment != null) {
          const obj = {
            idAttestato: idAttestato,
            nomeAllegato: attachment.name,
          };
          commit("modAttestatoInList", obj);
        }
        createAndSetSuccessAlert(
          dispatch,
          true,
          "Attestato aggiunto con successo"
        );
      } else {
        creaAlertErrore(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      creaAlertErrore(dispatch, error);
    }
  },

  async cancellaRigaAttestato(
    { commit, dispatch, state },
    { idContratto, idAbilitazione, idAttestato }
  ) {
    try {
      const axiosResponse = await this._vm.$api.abilitazioni.deleteAttestato(
        idContratto,
        idAbilitazione,
        idAttestato
      );

      if (isStatusSuccess(axiosResponse.status)) {
        commit("delAttestatoInLista", idAttestato);
        //Devo aggiornare lo stato e la scadenza dell'abilitazione
        let abilitazione = state.abilitazioneTemp;
        abilitazione.attestati = abilitazione.attestati.map((at) => ({
          ...at,
          nuovaScadenza: format(at.nuovaScadenza, DateDefaults.formats.CLIENT),
        }));
        abilitazione.scadenza = getScadenza(abilitazione);
        // console.log("stato", abilitazione.scadenza);
        abilitazione.stato = getStato(abilitazione);
        // console.log("stato", abilitazione.stato);
        //Lo aggiungo alla lista
        commit("modAbilitazioneInList", abilitazione);

        commit("setAbilitazioneTemp", abilitazione);
        createAndSetSuccessAlert(
          dispatch,
          true,
          "Attestato eliminato con successo"
        );
      } else {
        creaAlertErrore(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      creaAlertErrore(dispatch, error);
    }
  },
  async caricaAllegato(
    { commit, dispatch },
    { idContratto, idAbilitazione, attestato, attachment }
  ) {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("file", attachment.file);
      const axiosResponse = await this._vm.$api.abilitazioni.addAllegato(
        idContratto,
        idAbilitazione,
        attestato.id,
        bodyFormData
      );
      if (isStatusSuccess(axiosResponse.status)) {
        const obj = {
          idAttestato: attestato.id,
          nomeAllegato: attachment.name,
        };
        // console.log("caricaAllegato", obj);
        commit("modAttestatoInList", obj);
        createAndSetSuccessAlert(
          dispatch,
          true,
          "Allegato caricato con successo"
        );
      } else {
        creaAlertErrore(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      creaAlertErrore(dispatch, error);
    }
  },

  async eliminaAllegato(
    { commit, dispatch },
    { idContratto, idAbilitazione, idAttestato }
  ) {
    try {
      // console.log("eliminaAllegato", { idAbilitazione, idAttestato });
      const axiosResponse = await this._vm.$api.abilitazioni.deleteAllegato(
        idContratto,
        idAbilitazione,
        idAttestato
      );
      if (isStatusSuccess(axiosResponse.status)) {
        const obj = {
          idAttestato: idAttestato,
          nomeAllegato: null,
        };
        // console.log("eliminaAllegato", obj);
        commit("modAttestatoInList", obj);
        createAndSetSuccessAlert(
          dispatch,
          true,
          "Allegato eliminato con successo"
        );
      } else {
        creaAlertErrore(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      creaAlertErrore(dispatch, error);
    }
  },

  async visualizzaAllegato(
    { dispatch },
    { idContratto, idAbilitazione, idAttestato }
  ) {
    try {
      const axiosResponse = await this._vm.$api.abilitazioni.getAllegato(
        idContratto,
        idAbilitazione,
        idAttestato
      );

      if (isStatusSuccess(axiosResponse.status)) {
        // console.log(URL.createObjectURL(axiosResponse.data));
        return await URL.createObjectURL(axiosResponse.data);
      } else {
        creaAlertErrore(dispatch, axiosResponse.response.data);
      }
    } catch (error) {
      creaAlertErrore(dispatch, error);
    }
  },

  async fetchAbilitazioni({ commit }, idContratto) {
    const lista = await this._vm.$api.abilitazioni.fetchAbilitazioni(
      idContratto
    );
    if (lista != undefined) {
      commit("setAbilitazioniList", addPropertiesToArray(lista.data));
    }
  },

  setStatusOperation({ commit }, status) {
    commit("setStatusOperation", status);
  },

  setIsPrimoDisponibile({ commit }, isDisponibile) {
    commit("setIsPrimoDisponibile", isDisponibile);
  },
  setAbilitazioneTemp({ commit }, abilitazioneTemp) {
    commit("setAbilitazioneTemp", abilitazioneTemp);
  },
  setAbilitazioneDefault({ commit }, abilitazioneDefault) {
    commit("setAbilitazioneDefault", abilitazioneDefault);
  },

  setAttestatoTemp({ commit }, attestatoTemp) {
    commit("setAttestatoTemp", attestatoTemp);
  },
  setAttachment({ commit }, attachment) {
    commit("setAttachment", attachment);
  },
  setFileTemp({ commit }, value) {
    commit("setFileTemp", value);
  },
  setAbilitazioniConteggio({ commit }, abilitazioniConteggio) {
    commit("setAbilitazioniConteggio", _copyObject(abilitazioniConteggio));
  },
};

function isStatusSuccess(statusCode) {
  return statusCode >= 200 && statusCode <= 299;
}
function modificaAbilitazione(abilitazioneConfronto, abilitazioneModificata) {
  if (abilitazioneConfronto.id == abilitazioneModificata.id) {
    return abilitazioneModificata;
  } else {
    return abilitazioneConfronto;
  }
}

function creaAlertErrore(dispatch, msg, duration) {
  const alert = {
    show: true,
    type: AlertType.ERROR,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}

function createAndSetSuccessAlert(dispatch, show, msg, duration) {
  const alert = {
    show,
    type: AlertType.SUCCESS,
    msg,
    duration,
  };
  setAlert(dispatch, alert);
}

function setAlert(dispatch, alert) {
  dispatch("setAlert", alert, { root: true });
}
const mutations = {
  modAbilitazioneInList(state, ab) {
    state.abilitazioni = state.abilitazioni.map((e) =>
      modificaAbilitazione(e, ab)
    );
  },
  delAbilitazioneInList(state, ab) {
    state.abilitazioni = state.abilitazioni.filter(
      (abilitazione) => abilitazione.id != ab.id
    );
  },
  addAbilitazioneToList(state, ab) {
    state.abilitazioni.push(ab);
  },

  modAttestatoInList(state, { idAttestato, nomeAllegato }) {
    var abDaModificare = state.abilitazioneTemp;

    abDaModificare.attestati = abDaModificare.attestati.map(
      function changeAttestato(at) {
        if (at.id == idAttestato) {
          return {
            ...at,
            nomeAllegato: nomeAllegato,
            attachment:
              nomeAllegato == null
                ? null
                : Attachment.buildAttachmentByName(nomeAllegato),
          };
        } else {
          return at;
        }
      }
    );

    // console.log("abDaModificare", abDaModificare.attestati);

    state.attestatoTemp = abDaModificare.attestati.filter(
      (at) => at.id == idAttestato
    )[0];

    // console.log("state.attestatoTemp", state.attestatoTemp);

    //Fare ModAbilitazioneInList con l'abilitazione modificata
    state.abilitazioni = state.abilitazioni.map((e) =>
      modificaAbilitazione(e, abDaModificare)
    );
  },

  delAttestatoInLista(state, idAttestato) {
    //Recuperare dalla lista abilitazioni l'id corrispondente
    var abDaModificare = state.abilitazioneTemp;
    //Eliminare dalla lista attestati
    abDaModificare.attestati = abDaModificare.attestati.filter(
      (attestato) => attestato.id != idAttestato
    );
    //Fare ModAbilitazioneInList con l'abilitazione modificata
    state.abilitazioni = state.abilitazioni.map((e) =>
      modificaAbilitazione(e, abDaModificare)
    );
  },

  async addAttestatoToLista(state, abilitazioneModificata) {
    state.abilitazioneTemp = abilitazioneModificata;
    //Inserire nella lista attestati
    state.abilitazioni = state.abilitazioni.map((e) =>
      modificaAbilitazione(e, state.abilitazioneTemp)
    );
  },

  setAbilitazioneTemp(state, abilitazione) {
    state.abilitazioneTemp = abilitazione;
  },
  setAbilitazioneDefault(state, abilitazioneDefault) {
    state.abilitazioneDefault = abilitazioneDefault;
  },

  setAttestatoTemp(state, attestato) {
    state.attestatoTemp = attestato;
  },
  setIsPrimoDisponibile(state, isDisponibile) {
    state.isPrimoDisponibile = isDisponibile;
  },
  setAttachment(state, attachment) {
    state.attachment = attachment;
  },
  setAbilitazioniList(state, abilitazioniList) {
    state.abilitazioni = abilitazioniList;
  },

  setAbilitazioniConteggio(state, abilitazioniConteggio) {
    state.abilitazioniConteggio = abilitazioniConteggio;
  },

  setFileTemp(state, file) {
    state.fileTemp = file;
  },
  setStatusOperation: (state, status) => {
    state.statusOperation = status;
    if (!!state.statusOperation && state.statusOperation.status === "success") {
      setTimeout(
        () =>
          (state.statusOperation = {
            status: "",
            message: "",
          }),
        1000
      );
    } else if (
      !!state.statusOperation &&
      state.statusOperation.status === "error"
    ) {
      setTimeout(
        () =>
          (state.statusOperation = {
            status: "",
            message: "",
          }),
        3000
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
