import { mapActions, mapGetters } from "vuex";
import { Alert, AlertType } from "@/entities/alert";

const mixin = {
  methods: {
    ...mapActions({
      setAlert: "setAlert",
    }),

    createAndSetErrorAlert(message) {
      this.createAndSetAlert(message, null, AlertType.ERROR);
    },

    createAndSetSuccessAlert(message, duration) {
      this.createAndSetAlert(message, duration, AlertType.SUCCESS);
    },

    createAndSetWarnAlert(message, duration) {
      this.createAndSetAlert(message, duration, AlertType.WARNING);
    },

    createAndSetAlert(message, duration, type) {
      const alert = new Alert({
        msg: message,
        duration,
        type,
      });
      this.setAlert(alert);
    },
  },

  computed: {
    ...mapGetters({
      developmentMode: "developmentMode",
    }),
  },
};

export default mixin;
